import { Injectable, ɵsetAllowDuplicateNgModuleIdsForTest } from '@angular/core';
import { Dexie } from "dexie";
import { StorageService } from "./storage.service";
import { TranslateService } from "@ngx-translate/core";
import { isArray } from 'util';

@Injectable({
    providedIn: 'root'
})
export class LocaldbService {

    private db: Dexie = null;
    private dbVersion = 12;

    private tableMessages = 'messages';
    private tableDialogs = 'dialogs';
    private tableProfiles = 'profiles';
    private tableContacts = 'contacts';
    private tableGallery = 'gallery';
    private tableImages = 'images';
    private tableAPICalls = 'apicalls';

    private userHandle = '';

    constructor(
        private storageService: StorageService,
    ) { }

    async lazyInit() {
        if (this.db == null) {
            await this.init();
        }
    }

    private async tearDown() {
        if (this.db == null) return;
        if (this.db.isOpen()) {
            try {
                this.db.close();
            } catch (error) {
                console.log("LOCALDB","CLOSE",error)
            }
        }
        try {
            await this.db.delete();
        } catch (error) {
            console.log("LOCALDB","DELETE",error)
        }
        this.db = null;
    }


    public async setUserHandle(userhandle: string) {

        if (userhandle == null) userhandle = "";
        userhandle = userhandle.trim();

        if (userhandle == "") {
            await this.tearDown();
            return;
        }

        if (this.db != null) {

            if (this.db.name == ("localDB_" + userhandle)) {
                if (this.db.isOpen()) {
                    return;
                }
                await this.db.open();
                return;
            }

            await this.tearDown();
        }

        this.userHandle = userhandle;
        await this.init();
    }

    async init() {
        if (this.userHandle == "") throw new Error("cannot open database");

        this.db = new Dexie("localDB_" + this.userHandle);

        await this.db.version(this.dbVersion).stores({
            messages: "++local_id, &id, from_handle, to_handle",
            dialogs: "++local_id, &user_handle, last_update",
            profiles: "++local_id, &user_handle",
            contacts: "++local_id, *contact_hashed_numbers, user_handle, contact_name, hash,sync_state",
            gallery: "++local_id, user_handle, image",
            visitors: "++local_id, user_handle, visited_user_handle, last_visit",
            images: "++local_id,createdat,expiresat,kind,handle,resolution,[handle+resolution+kind]",
            apicalls: "++local_id,createdat,expiresat,request"
        });

        await this.db.open();
    }

    async setDialog(dialog_data: any) {
        await this.lazyInit();

        let d = await this.getDialog(dialog_data.user_handle);
        if (d == null) {
            return this.db.table(this.tableDialogs).put(dialog_data);
        } else {
            return this.db.table(this.tableDialogs).update(d.local_id, dialog_data);
        }
    }

    async setProfile(profile_data: any) {
        await this.lazyInit();

        let d = await this.getProfile(profile_data.user_handle);
        if (d == null) {
            return this.db.table(this.tableProfiles).put(profile_data);
        } else {
            return this.db.table(this.tableProfiles).update(d.local_id, profile_data);
        }
    }

    async getProfile(user_handle: string) {
        await this.lazyInit();
        return this.db.table(this.tableProfiles).get({ 'user_handle': user_handle });
    }

    async getDialog(user_handle: string) {
        await this.lazyInit();
        return this.db.table(this.tableDialogs).get({ 'user_handle': user_handle });
    }

    async removeDialog(user_handle: string) {
        await this.lazyInit();
        return this.db.table(this.tableDialogs).where('user_handle').equals(user_handle).delete();
    }

    // this is sadly very complicated because all timestamps and counters also apply to action-messages
    // which we do not want to count when sorting by or displaying last communication
    getLastMessageTime(a) {
        // most basic value i have
        let av = a.last_update

        // last received/sent message is better... (if available)
        if (a.counters != null) {
            let counter_timestamp = 0
            if ((a.counters.incoming != null) && (a.counters.incoming.queued != null)) {
                let v = a.counters.incoming.queued.last_update
                if (v != null) counter_timestamp = v
            }

            if ((a.counters.outgoing != null) && (a.counters.outgoing.delivered != null)) {
                let v = a.counters.outgoing.queued.last_update
                if (v != null) {
                    if (v > counter_timestamp) {
                        counter_timestamp = v
                    }
                }
            }
            if (counter_timestamp > 0) {
                av = counter_timestamp
            }
        }

        // ... but those can be action-messages aswell, so: last received text is best (if available)
        let message_timestamp = 0
        if (a.last_received_text != null) message_timestamp = a.last_received_text
        if (a.last_sent_text != null) {
            if (a.last_sent_text > message_timestamp) {
                message_timestamp = a.last_sent_text
            }
        }
        if (message_timestamp > 0) {
            av = message_timestamp
        }
        return av
    }

    async getDialogs() {
        await this.lazyInit();
        let dialogs = await this.db.table(this.tableDialogs).orderBy('last_update').reverse().toArray();

        for (let i = 0; i < dialogs.length; i++) {
            dialogs[i].last_message_time = this.getLastMessageTime(dialogs[i]);
        }

        dialogs.sort((a,b) => {
            // most basic value i have
            let av = this.getLastMessageTime(a)
            let bv = this.getLastMessageTime(b)

            // if dialogs have updates at the same minute, move those dialogs up in the list
            // that have unread messages
            let iav = Math.floor(av / 60000)
            let ibv = Math.floor(bv / 60000)
            if (iav == ibv) {
                if ((a.new_messages > 0) && (b.new_messages == 0)) {
                    return -1;
                }
                if ((a.new_messages == 0) && (b.new_messages > 0)) {
                    return 1;
                }
            }
            return b.last_message_time - a.last_message_time
        })

        console.log("CHAT", dialogs)

        return dialogs
    }

    async putMessage(message) {
        await this.lazyInit();
        let d = await this.getMessage(message.id);
        if (d == null) {
            return this.db.table(this.tableMessages).put(message);
        } else {
            return this.db.table(this.tableMessages).update(d.local_id, message);
        }
    }

    async removeMessage(id: string) {
        await this.lazyInit();
        return this.db.table(this.tableMessages).where('id').equals(id).delete();
    }

    async getMessage(id: number) {
        await this.lazyInit();
        return this.db.table(this.tableMessages).get({ 'id': id });
    }

    async getMessages(user_handle: string, from_id: number, to_id: number, maxcount: number) {
        await this.lazyInit();
        await this.deleteExpiredMessages(user_handle)
        let res = await this.db.table(this.tableMessages)
            .where('id')
            .between(from_id, to_id, true, true)
            .filter(function (v) { return ((v.from_handle == user_handle) || (v.to_handle == user_handle)); })
            .limit(maxcount).toArray();

        // update state for self-destruct messages
        // message has to be: 
        // - not deleted (wo wont process these)
        // - a self-destruct message (_disappear == true)
        // - has its deadline set (!= false)
        let now = Date.now();
        for (let i = 0; i < res.length; i++) {
            let m = res[i];

            if (m._deleted) continue;
            if (!m._disappear) continue;
            if (m.deadline === false) continue;

            if (m.deadline < now) {
                m.message = "";
                m.attachments = {};
                m._deleted = true;
            }
        }
        return res;
    }

    async getUnhandledActionMessages(user_handle: string) {
        await this.lazyInit();
        return this.db.table(this.tableMessages)
            .filter(function (v) { return (!v._processed && (v._type == "action")); })
            .filter(function (v) { return ((v.from_handle == user_handle) || (v.to_handle == user_handle)); })
            .toArray();
    }

    async getUnreadMessages(user_handle: string) {
        await this.lazyInit();
        return this.db.table(this.tableMessages)
            .filter(function (v) { return (v._status == 'received'); })
            .filter(function (v) { return (v.from_handle == user_handle); })
            .filter(function (v) { return (v._visible == true); })
            .toArray();
    }

    async updateIncomingMessageStatus(user_handle: string, from_id: number, to_id: number, status: string) {

        await this.lazyInit();

        let data = await this.db.table(this.tableMessages)
            .where('id')
            .between(from_id, to_id, true, true)
            .filter(function (v) { return (v.from_handle == user_handle); })
            .filter(function (v) { return ((v._status != status)) })
            .toArray();

        await data.forEach(async item => {
            await this.db.table(this.tableMessages).where({ local_id: item.local_id }).modify({ _status: status });
        });
    }

    async updateOutgoingMessageStatus(user_handle: string, from_id: number, to_id: number, status: string) {

        await this.lazyInit();

        let data = await this.db.table(this.tableMessages)
            .where('id')
            .between(from_id, to_id, true, true)
            .filter(function (v) { return (v.to_handle == user_handle); })
            .filter(function (v) { return ((v._status != status)) })
            .toArray();

        await data.forEach(async item => {
            await this.db.table(this.tableMessages).where({ local_id: item.local_id }).modify({ _status: status });
        });
    }

    async deleteExpiredMessages(user_handle: string) {

        await this.lazyInit();

        let timelimit = Date.now();
        let data = await this.db.table(this.tableMessages)
            .filter(function (v) { return v.disappear; })
            .filter(function (v) { return (v.deadline < timelimit); })
            .toArray();

        // TODO: delete from image-cache
        data.forEach(async item => {
            if (item.deadline === false) return;

            let att = item.attachments
            if (Array.isArray(att)) {
                for (let i = 0; i < att.length; i++) {
                    await this.removeImageByHandleAndKind("ATTACHMENT",item.id + "_" + att[i])
                }
            }

            await this.db.table(this.tableMessages).where({ local_id: item.local_id }).modify({ 
                attachments: {},
                message: "",
                _deleted: true
            });
        });
    }

    // delete the dialog entry and all messages related to that dialog
    async deleteDialog(user_handle: string) {
        await this.lazyInit();
        await this.db.table(this.tableDialogs).where({ 'user_handle': user_handle }).delete();
        await this.db.table(this.tableMessages).where({ 'from_handle': user_handle }).delete();
        await this.db.table(this.tableMessages).where({ 'to_handle': user_handle }).delete();
    }


    async getLocalContacts() {
        await this.lazyInit();
        return this.db.table(this.tableContacts).orderBy('contact_name').toArray();
    }

    async getLocalContact(user_handle) {
        await this.lazyInit();
        let list = await this.db.table(this.tableContacts).where('user_handle').equals(user_handle).toArray();
        if (list.length == 0) return null;
        return list[0];
    }

    async updateContacts(handles: object) {
        await this.lazyInit();

        for (const [hashedNumber, userHandle] of Object.entries(handles)) {
            await this.db.table(this.tableContacts).where('contact_hashed_numbers').equals(hashedNumber).toArray().then((row) => {
                if (row.length > 0) {
                    this.db.table(this.tableContacts).update(row[0]['local_id'], {user_handle: userHandle});
                }
            }).catch((e) => {});
        }
    }

    async saveContacts(contacts: any) {
        await this.lazyInit();

        contacts.map(async (n: object) => {
            await this.db.table(this.tableContacts).add(n);
        });
    }

    async putContact(contact) {
        await this.lazyInit();
        return await this.db.table(this.tableContacts).put(contact);
    }

    async removeContact(contact) {
        await this.lazyInit();
        await this.db.table(this.tableContacts).where('hash').equals(contact.hash).delete()
    }



    /*
    we use this table to temporarily save the uploaded images, in case the user closes the app
    once the last view was the upload modal, the app will get the images and show them on the modal again.
    to give the user the chance to save the images properly.
    after the images have been sent to server, this table will be cleaned up.
    therefore, we always clean the table then insert the data
     */
    async saveImages(images) {
        if (images.length > 0) {
            if (this.userHandle == "") return;
            await this.lazyInit();

            this.db.table(this.tableGallery).clear();

            for (const image of images) {
                this.db.table(this.tableGallery).put({ user_handle: this.userHandle, image: image });
            }
        }
    }

    async deleteImages() {
        await this.lazyInit();
        if (this.userHandle == "") return;
        await this.db.table(this.tableGallery).clear();
    }

    async getImages() {
        await this.lazyInit();
        if (this.userHandle == "") return;
        return this.db.table(this.tableGallery).where('user_handle').equals(this.userHandle).toArray();
    }

    async emptyTable(tableName: string) {
        await this.lazyInit();
        return await this.db.table(this.tableContacts).clear();
    }

    async deleteDatabase() {
        await this.lazyInit();
        const databases = await Dexie.getDatabaseNames();

        for (const databaseName of databases) {
            if (databaseName.indexOf('localDB_') !== -1) {
                await Dexie.delete(databaseName); // .then((r) => {}).catch((e) => {});
                this.db = null;
            }
        }
    }

    async showTables() {
        await this.lazyInit();
        return await this.db.tables.forEach(function (table) {
            console.log(table);
        });
    }

    async getImage(kind,handle,resolution: string) {
        await this.lazyInit();
        return await this.db.table(this.tableImages).get({ 'handle': handle, 'resolution': resolution, 'kind': kind });
    }

    async putImage(data) {
        await this.lazyInit();
        return await this.db.table(this.tableImages).put(data);
    }

    async removeImageByHandleAndKind(kind,handle: string) {
        await this.lazyInit();

        let test = await this.db.table(this.tableImages)
        .where('handle').equals(handle)
        .filter(function (v) { return (v.kind == kind); }).toArray()
        console.log(test)

        return await this.db.table(this.tableImages)
            .where('handle').equals(handle)
            .filter(function (v) { return (v.kind == kind); })
            .delete();
    }

    async removeImageByHandle(handle: string) {
        await this.lazyInit();

        return await this.db.table(this.tableImages)
            .where('handle').equals(handle)
            .delete();
    }


    async removeImageById(id) {
        await this.lazyInit();

        return await this.db.table(this.tableImages)
            .where({ local_id: id })
            .delete();
    }

    async getAllImages(kind,handle: string) {
        await this.lazyInit();
        return await this.db.table(this.tableImages).where({ 'handle': handle, 'kind': kind }).toArray();
    }


    async getAPICall(request) {
        await this.lazyInit();
        return await this.db.table(this.tableAPICalls).get({ 'request': request});
    }

    async putAPICall(data) {
        await this.lazyInit();
        return await this.db.table(this.tableAPICalls).put(data);
    }

    async removeAPICall(request) {
        await this.lazyInit();

        return await this.db.table(this.tableAPICalls)
            .where('request').equals(request)
            .delete();
    }

    async removeAPICallStartsWith(request) {
        await this.lazyInit();
        let candidates = await this.db.table(this.tableAPICalls).where('request').startsWith(request)
        console.log(await candidates.toArray())
        return await candidates.delete()
        
    }

    async cleanUpAPICalls(maxitems) {
        await this.lazyInit()

        const itemCount = await this.db.table(this.tableAPICalls).count()
        if (itemCount <= maxitems) return

        const oldestItems = await this.db.table(this.tableAPICalls).orderBy('createdat').limit(itemCount-maxitems).primaryKeys()
        await this.db.table(this.tableAPICalls).bulkDelete(oldestItems)
    }


    async cleanUpImages(maxSmallImages,maxLargeImages) {
        await this.lazyInit()

        let largeImages = await this.db.table(this.tableImages)
            .where('resolution').equals('org')
            .primaryKeys()

        if (largeImages.length > maxLargeImages) {
            largeImages.sort()
            largeImages = largeImages.slice(0,largeImages.length-maxLargeImages)
    
            await this.db.table(this.tableImages).bulkDelete(largeImages)
        }


        let smallImages = await this.db.table(this.tableImages)
            .where('resolution').notEqual('org')
            .primaryKeys()

        if (smallImages.length > maxSmallImages) {
            smallImages.sort()
            smallImages = smallImages.slice(0,smallImages.length-maxSmallImages)
    
            await this.db.table(this.tableImages).bulkDelete(smallImages)
        }
    }

}
