<ion-app>
  <ion-router-outlet></ion-router-outlet>

  <ion-tabs (ionTabsDidChange)="setCurrentTab($event)" #tabs>
    <ion-tab-bar slot="bottom" #myTabBar id="desadeTabs">

      <ion-tab-button tab="dialoglist">
        <ion-badge *ngIf="newMessages > 0" color="danger" style="font-size: 12px;">{{newMessages}}</ion-badge>
        <ion-icon name="chatbubbles-outline" [style]="dialogsColor"></ion-icon>
        <ion-label [style]="dialogsColor">{{"TAB_BAR.DIALOGS" | translate}}</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="whodoeswhat" (click)="wdwclick($event)">
        <ion-icon name="man-outline" [style]="whodoeswhatColor"></ion-icon>
        <ion-label [style]="whodoeswhatColor">Wer was</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="profile-search">
        <ion-icon name="search-outline" [style]="searchColor"></ion-icon>
        <ion-label [style]="searchColor">{{"TAB_BAR.SEARCH" | translate}}</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="profile/self" class="widedisplay">
        <ion-icon name="person-outline" [style]="profileColor"></ion-icon>
        <ion-label [style]="profileColor" class="widetext">Mein Profil</ion-label>
        <ion-label [style]="profileColor" class="mediumtext">Ich</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="settings" class="widedisplay">
        <ion-icon name="settings" [style]="settingsColor"></ion-icon>
        <ion-label [style]="settingsColor" class="widetext">Einstellungen</ion-label>
        <ion-label [style]="settingsColor" class="mediumtext">Einst.</ion-label>
      </ion-tab-button>

      <ion-tab-button (click)="showMore()" class="narrowdisplay">
        <ion-icon name="ellipsis-horizontal-circle"></ion-icon>
        <ion-label>Mehr</ion-label>
      </ion-tab-button>

      <span style="display: block; position: absolute; top: 2px; left: 2px; color: #888 !important;">
        <ion-icon name="radio-button-on-outline" size="small" color="{{color}}" style="width: 6px;"></ion-icon>
      </span>
    </ion-tab-bar>
  </ion-tabs>

  <div id="black" style="display: none; position: fixed; left: 0px; top: 0px; right: 0px; bottom: 0px; background-color: rgba(0,0,0,0.9); z-index: 1000;"></div>

</ion-app>
