import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {EventsService} from "./events.service";
import {LocaldbService} from "./localdb.service";
import { Network } from '@capacitor/network';

const ERR_OFFLINE = "offline";
const ERR_FAILED = "failed";
const ERR_NOTFOUND = "not_found";
const ERR_YOUAREBLOCKED = "you_are_blocked";
const ERR_YOUDIDBLOCK = "you_did_block";
const ERR_CONGESTION = "congestion";
const ERR_NONRECEIVER = "non_receiver";
const ERR_REPETITION = "repetition";
const ERR_LIMITREACHED = "limit_reached";
const OK = "ok";

const IDLECHECK_INTERVAL = 10 * 1000;


const DEFAULT_RESOLVE_BATCH = 10;
const DEFAULT_TYPING_THROTTLE_MILLIS = 5000;
const DEFAULT_RECONNECT_INTERVAL = 120 * 1000;
const DEFAULT_IDLE_TIMEOUT = 180 * 1000;


@Injectable({
  providedIn: 'root'
})

export class ChatService {

  private RESOLVE_BATCH = 10;
  private TYPING_THROTTLE_MILLIS = 5000;
  private RECONNECT_INTERVAL = 120 * 1000;
  private IDLE_TIMEOUT = 180 * 1000;

  private connection_state = 'disconnected';

  private user_handle = "";
  private bearertoken = "";

  private in_update = false;
  private update_queue = [];

  private last_typing = 0;

  private connectCallback = null;
  private lastIdleCheck = 0;

  private last_networkStatus = false;

  private online_contacts = [];
  private online_contacts_map = {};

  constructor(
    private socket: Socket,
    private eventsService: EventsService,
    private localDBService: LocaldbService
  ) {

    Network.getStatus().then(status => {this.last_networkStatus = status.connected;});
    Network.addListener("networkStatusChange",status => {
      let last_status = this.last_networkStatus;
      this.last_networkStatus = status.connected;
      console.log("CHATSERVICE: networkstatus changed",status);
      if ((status.connected == true) && (last_status == false)) {
        this.interact();
      }
    });

    console.log("CHATSERVICE: constructor");

    this.socket.on('connect', () =>{
      console.log("CHATSERVICE: connected");
      this.lastIdleCheck = Date.now();
      this.setConnectionState('connected');
      this.authenticate();
    });

    this.socket.on('connect_error', (result) => {
      console.log("CHATSERVICE: connection error",result);
      this.lastIdleCheck = Date.now();
      this.setConnectionState('disconnected');
      this.executeConnectCallback("connect_error");
    });

    this.socket.on('disconnect', (result) => {
      console.log("CHATSERVICE: disconnect",result);
      this.lastIdleCheck = Date.now();
      this.setConnectionState('disconnected');
      this.executeConnectCallback("disconnected");
    });

    this.socket.on('typing', (user_handle) => {
      console.log("CHATSERVICE: received typing-event for " + user_handle);
      this.lastIdleCheck = Date.now();
      this.eventsService.publishTyping(user_handle);
    });

    this.socket.on('updates', (data,callback) => {
      this.lastIdleCheck = Date.now();
      this.enqueueUpdate(data);
      if (callback) callback("ok");
    });

    this.socket.on('onlinestatus', () => {
      console.log("CHATSERVICE: onlinestatus");
      this.refreshonlinecontacts();
    });

    this.socket.on('error', (data) => {
      console.log(data);
    });

    this.idleCheckTimer();
  }

  // updates have to be processed one-by-one
  // enqueueUpdate maintains a FIFO for update-messages
  // messages are put to a queue and if no other async-function
  // is running, drains the queue
  private async enqueueUpdate(data) {

    this.update_queue.push(data);

    if (this.in_update) return;
    this.in_update = true;

    try {
      while (this.update_queue.length > 0) {
        let data = this.update_queue.shift();
        await this.handleUpdates(data);
      }
    } catch (e) {
      console.log("CHATSERVICE: error in enqueueUpdate",e);
    }

    this.in_update = false;
  }

  private setConnectionState(state: string) {
    console.log("CHATSERVICE: connection state is " + state + " for " + this.user_handle + ":" + this.bearertoken);

    this.connection_state = state;
    this.eventsService.publishConnectionState(state);
  }

  private executeConnectCallback(message) {
    let c = this.connectCallback
    this.connectCallback = null;
    if (c == null) return;
    console.log("CHATSERVICE: executing connection callback ",message);
    try {
      c(message);
    } catch (e) {
      console.log("CHATSERVICE: execption in connect callback",e);
    }
  }

  private idleCheckTimer() {
    let that = this;
    try {
      if ((that.user_handle != "") && (that.bearertoken != "")) {
        console.log("CHATSERVICE: idleCheckTimer");
        let elapsed = (Date.now()) - that.lastIdleCheck;

        if (that.isConnected()) {
          if (elapsed > this.IDLE_TIMEOUT) {
            that.lastIdleCheck = Date.now();
            that.disconnect();
          }
        } else {
          if (elapsed > this.RECONNECT_INTERVAL) {
            that.lastIdleCheck = Date.now();
            that.interact();
          }
        }
      }

    } catch (e) {
      console.log("CHATSERVICE: exception in idlechecktimer",e)
    }

    window.setTimeout( function() {
      that.idleCheckTimer()
    }, IDLECHECK_INTERVAL );

  }



  private chatHasBeenReset(chat_then,chat_now) {
    return (chat_then.reset_barrier != chat_now.reset_barrier) || (chat_then.chat_id != chat_now.chat_id);
  }

  // how many messages went in one direction of this chat (in/out) and
  // what is the most recent message id?
  private maxMessageIdAndCountPerDirection(direction,chat) {
    let counter = null;
    if (direction == "incoming") {
      // for incoming messages: what was delivered to me
      counter = chat.counters.incoming.delivered;
    } else {
      // for outgoing messages: what did i send (regardless if it was delivered or not)
      counter = chat.counters.outgoing.queued;
    }
    return {
      "count": parseInt(counter["count"]),
      "id": parseInt(counter["message_id"])
    }
  }

  // how many messages are in this chat and what is the most recent message id?
  private maxMessageIdAndCountTotal(chat) {
    let incoming = this.maxMessageIdAndCountPerDirection("incoming",chat);
    let outgoing = this.maxMessageIdAndCountPerDirection("outgoing",chat);

    return {
      "count": incoming.count + outgoing.count,
      "id": Math.max(incoming.id,outgoing.id)
    }
  }


  private firstMessageId(chat) {
    let incoming = chat.counters.incoming.delivered.first_message_id;
    let outgoing = chat.counters.outgoing.queued.first_message_id;

    if (incoming == 0) return outgoing;
    if (outgoing == 0) return incoming;
    return Math.min(incoming,outgoing);
  }

  // how many messages are between two updates?
  // what are the ids of the messages on both ends of the gap?
  private calculateMessageGap(chat_then,chat_now) {

    // this is basically calculated as a difference between
    // the counters for "then" and "now"...
    let lower = {
      "count": 0,
      "id": 0
    };
    if (chat_then != null) {
      lower = this.maxMessageIdAndCountTotal(chat_then);
    }
    if (lower.id == 0) {
      lower.id = this.firstMessageId(chat_now);
    }
    let upper = this.maxMessageIdAndCountTotal(chat_now);

    // ... but if there have been messages delivered alongside with
    // this update, the upper-id and counters have to be set to the
    // value of the first message in the list
    if (chat_now.messages != null) {
      upper.count -= chat_now.messages.length;

      for (let i = 0; i < chat_now.messages.length; i++) {
        let msgid = parseInt(chat_now.messages[i].id);
        if (msgid < upper.id) {
          upper.id = msgid;
        }
      }
    }

    // TODO: check! are these separate cases!?
    if ((upper.id-lower.id) == 1) return null;
    if ((upper.count-lower.count) == 0) return null;
    if ((upper.count-lower.count) < 0) {
      console.log("CHATSERVICE: gapinfo < -1");
      return null;
    }

    return {
      "lower_id": lower.id,
      "upper_id": upper.id,
      "count": upper.count-lower.count
    }
  }

  // adds a special "message" to the messagelist. everytime a view comes across
  // this message it knows it has to resolve/load those missing items
  private async insertMessageGap(chat,lower_id,upper_id,count) {
    if ((upper_id-lower_id) == 1) return;
    if ((count) == 0) return;

    let msg = {
      "id": lower_id+1, // this is a fake id, guaranteed to reside within the gap
      "from_handle": chat.user_handle,
      "from_nick": chat.user_nick,
      "to_handle": this.user_handle,
      "message": "gap",
      "_visible": false,
      "_type": "gap",
      "_lower_id": lower_id,
      "_upper_id": upper_id,
      "_count": count
    }

    await this.localDBService.putMessage(msg);
  }

  // updates come in batches. process every item in the batch
  private async handleUpdates(data) {
    for (let i = 0; i < data.length; i++) {
      await this.handleChatUpdate(data[i])
    }
  }

  // handle update for an individual chat
  private async handleChatUpdate(chat_now) {

    // some processing requires comparing the current update to what we had before
    let chat_then = await this.localDBService.getDialog(chat_now.user_handle);

    // this is a new chat ! (either totally new or it is a new chat after reset)
    if ( (chat_then == null) || (this.chatHasBeenReset(chat_then,chat_now)) ) {
      // delete all messages for this chat (for safety: there might have been leftovers)
      await this.localDBService.deleteDialog(chat_now.user_handle);
    }

    console.log("CHATSERVICE: begin update");
    console.log("CHATSERVICE: ",this.maxMessageIdAndCountPerDirection("incoming",chat_now),this.maxMessageIdAndCountPerDirection("outgoing",chat_now));
    if (chat_then != null) {
      console.log("CHATSERVICE: ",this.maxMessageIdAndCountPerDirection("incoming",chat_then),this.maxMessageIdAndCountPerDirection("outgoing",chat_then));
    }
    if (chat_now.messages != null) {
      for (let i = 0; i < chat_now.messages.length; i++) {
        console.log("CHATSERVICE: ",chat_now.messages[i]["id"], chat_now.messages[i]["message"]);
      }
    }

    // create gap-entry for messages before those supplied in the chat-structure
    // (ofcourse only if there is a gap)
    let gapInfo = this.calculateMessageGap(chat_then,chat_now);
    if (gapInfo != null) {
      console.log("CHATSERVICE: insert message gap",gapInfo);
      await this.insertMessageGap(
        chat_now,
        gapInfo.lower_id,
        gapInfo.upper_id,
        gapInfo.count
      );
      if ((chat_now["last_received_text"] == null) || (chat_now["last_received_text"] == 0)) {
        chat_now.last_received_text = 1
      }
      if ((chat_now["last_sent_text"] == null) || (chat_now["last_sent_text"] == 0)) {
        chat_now.last_sent_text = 1
      }

    }

    // put everyting into the db
    let message_times = await this.handleMessageUpdates(chat_now,chat_now.messages);
    if (message_times[0] > 0) {
      chat_now.last_received_text = message_times[0]
    }
    if (message_times[1] > 0) {
      chat_now.last_sent_text = message_times[1]
    }
    if (message_times[0] > message_times[1]) {
      chat_now.last_message = message_times[0]
    } else {
      chat_now.last_message = message_times[1]
    }
    

    // update database-messages' status for "delivered" and "displayed" (outgoing)
    await this.updateOutgoingMessageStatus(chat_now);
    await this.updateIncomingMessageStatus(chat_now);


    // TODO: calculate REAL number of new (i.e. not yet displayed) messages
    let unread = await  this.localDBService.getUnreadMessages(chat_now.user_handle);
    chat_now.new_messages = unread.length;
    await this.localDBService.setDialog(chat_now);


    // tell the app there are updates in the db ready to show
    this.eventsService.publishUpdate(chat_now);
    console.log("CHATSERVICE: end update");

  }


  // handle the "delivered" and "displayed" updates for outgoing messages
  private async updateOutgoingMessageStatus(chat) {

    let counter = chat.counters.outgoing;

    // mark all messages displayed on chat-buddies device
    await this.localDBService.updateOutgoingMessageStatus(
      chat.user_handle,
      0,
      counter.displayed.message_id,
      'displayed'
    );


    if (counter.delivered.message_id > counter.displayed.message_id) {
      // mark all messages transmitted to chat-buddy
      await this.localDBService.updateOutgoingMessageStatus(
        chat.user_handle,
        counter.displayed.message_id+1,
        counter.delivered.message_id,
        'transmitted'
      );
    }
  }


  // mark incoming messages as displayed
  private async updateIncomingMessageStatus(chat) {

    let counter = chat.counters.incoming;

    // mark all received messages as read
    await this.localDBService.updateIncomingMessageStatus(
      chat.user_handle,
      0,
      counter.displayed.message_id,
      'displayed'
    );

  }

  // message updates come in batches. process every item in the batch
  private async handleMessageUpdates(chat,messages) {
    let last_text_received = 0
    let last_text_sent = 0

    if (messages == null) return [0,0];
    for (let i = 0; i < messages.length; i++) {
      let message = messages[i];
      message = this.enrichMessage(chat,message);
      if ((message._type == "message") && (message._direction == "in")) {
        if (message.datetime > last_text_received) last_text_received = message.datetime
      }
      if ((message._type == "message") && (message._direction == "out")) {
        if (message.datetime > last_text_sent) last_text_sent = message.datetime
      }
      messages[i] = message;
      await this.localDBService.putMessage(message);
    }

    // try to execute all unhandled action messages
    await this.handleActionMessages(chat);

    // expire (i.e. delete) all self-destruct messages
    await this.localDBService.deleteExpiredMessages(chat.user_handle);

    return [last_text_received,last_text_sent]
  }

  // check for unprocessed action messages. process every item in the batch
  private async handleActionMessages(chat) {
    let messages = await this.localDBService.getUnhandledActionMessages(chat.user_handle);
    for (let i = 0; i < messages.length; i++) {
      await this.handleActionMessage(messages[i]);
    }
  }

  // action messages start with a special code...
  private isActionMessage(message) {
    if (message.message == null) return false;
    return (message.message.indexOf('#!{') == 0);
  }

  private isSecretMessage(message) {
    if (message.message == null) return false;
    return (message.message.indexOf('#?') == 0);
  }

  private handleSecretMessage(message) {
    if (!this.isSecretMessage(message)) return

    let raw = message.message.trim().split('?',3)
    if (raw.length != 3) return

    message.message = raw[2]
    message._secret = raw[1]
    message._issecret = true
  }


  // isEmojiMessage returns true if the message consists only of emojis (1-4)
  private isEmojiMessage(text) {
    const classifier = /^\p{Extended_Pictographic}{1,4}$/u;
    return (text.match(classifier) != null);
  }
  // enrichMessage adds dervied attributes from the bare message
  private enrichMessage(chat,message) {

    message = JSON.parse(JSON.stringify(message));
    message._deleted = message.deleted;
    message._visible = message.visible;
    message._locked = message.locked;
    message._disappear = (message.disappear === true);
    message._ignited = false;
    message._action = "";
    message._issecred = false;

    // special treatment for action messages
    if (this.isActionMessage(message)) {
      message._type = "action";
      message._processed = false;
    } else {
      message._type = "message";
    }

    this.handleSecretMessage(message)

    message.message = message.message.trim();
    message._emojionly = this.isEmojiMessage(message.message);

    if (message.from_handle == chat.user_handle) {
      message._direction = 'in';
      if (chat.counters.incoming.displayed.message_id < message.id) {
        message._status = 'received'; // received, not yet displayed
      } else {
        message._status = 'read'; // received and displayed
      }

      // selfdestruct messages are at first invisible to the reciever
      // they become visible once a deadline is set
      /*
      if (message.disappear === true) {
        message._visible = false;
      }*/

      return message;
    }

    message._direction = 'out';
    if (chat.counters.outgoing.displayed.message_id >= message.id) {
      // doppel blau
      message._status = 'displayed';
    }
    else if (chat.counters.outgoing.delivered.message_id >= message.id) {
      // doppelt grau
      message._status = 'transmitted';
    }
    else if (chat.counters.outgoing.queued.message_id >= message.id) {
      // one tick
      message._status = 'sent';
    }
    else {
      // noch nicht verschickt
      message._status = 'queued';
    }


    // selfdestruct messages are visible to the sender
    if (message.disappear === true) {
      message._visible = true;
    }

    return message;
  }


  private async handleActionMessage(message) {
    if (message == null) return false;
    if (message._type != "action") return false;

    console.log("CHAT","action-message",message)

    let payload = message.message.substring(2);
    payload = JSON.parse(payload);

    // if the payload is invalid, consider the actionmessage as processed
    if (!payload) {
      message._processed = true;
      await this.localDBService.putMessage(message);
      return true;
    }

    // is this a delete request for me? no! treat as handled
    if (message.to_handle != this.user_handle) {
      message._processed = true;
      await this.localDBService.putMessage(message);
      return true;
    }

    // process delete actions
    if (payload.action == "deletemessage") {
      let messageToDelete = await this.localDBService.getMessage(payload.message_id);
      if ((messageToDelete != null) && (messageToDelete._deleted == false)) {

        messageToDelete._deleted = true;
        messageToDelete.message = "";
        await this.localDBService.putMessage(messageToDelete);

        message._processed = true;
        await this.localDBService.putMessage(message);

        return true;
      }
    }

    if (payload.action == "revealmessage") {
      let messageToReveal = await this.localDBService.getMessage(payload.message_id);
      if ((messageToReveal != null) && (messageToReveal._deleted == false)) {

        messageToReveal._locked = false;
        messageToReveal.message = payload.message;
        messageToReveal.attachments = payload.attachments;
        await this.localDBService.putMessage(messageToReveal);

        message._processed = true;
        await this.localDBService.putMessage(message);

        return true;
      }
    }

    if (payload.action == "ignitemessage") {
      let messageToIgnite = await this.localDBService.getMessage(payload.message_id);
      let now = Date.now();

      if (messageToIgnite != null) {

        messageToIgnite.visible = now > payload.deadline;
        messageToIgnite.deadline = payload.deadline;
        messageToIgnite._ignited = true;
        messageToIgnite.deadline_start = message.datetime;
        await this.localDBService.putMessage(messageToIgnite);

        message._processed = true;
        await this.localDBService.putMessage(message);

        return true;
      }
    }

    if (payload.action == "block") {
      message._action = "block";
      message._processed = true;
      await this.localDBService.putMessage(message);
      return true;
    }

    if (payload.action == "unblock") {
      message._action = "unblock";
      message._processed = true;
      await this.localDBService.putMessage(message);
      return true;
    }

    if (payload.action == "befriend") {
      message._action = "befriend";
      message._processed = true;
      await this.localDBService.putMessage(message);
      return true;
    }

    if (payload.action == "defriend") {
      message._action = "defriend";
      message._processed = true;
      await this.localDBService.putMessage(message);
      return true;
    }

    if (payload.action == "dropped") {
      message._action = "dropped";
      message._dropped = payload.text;
      message._processed = true;
      await this.localDBService.putMessage(message);
      return true;
    }

    if (payload.action == "attention") {
      message._action = "attention";
      message._processed = true;
      await this.localDBService.putMessage(message);
      return true;
    }

    // all other (unhandled) actionmessages are considered processed from here on
    message._processed = true;
    await this.localDBService.putMessage(message);
    return true;
  }

  private async connect(callback) {
    if ((this.user_handle == "") && (this.bearertoken == "")) {
      if (callback != null) callback("no_credentials");
      return ;
    }

    if (this.connection_state == 'disconnected') {
      this.connectCallback = callback;
      this.setConnectionState('connecting');
      try {
        this.socket.connect();
      } catch (e) {
        console.log(e);
      }
    }

  }

  public disconnect() {
    if (this.connection_state != 'disconnected') {
      this.setConnectionState('disconnecting');
      this.socket.disconnect();
    }
  }

  private async authenticate() {

    let r: any = await new Promise(resolve => this.socket.emit(
      'auth',
      this.bearertoken,
      -1,
      data => resolve(data)
    ))

    this.lastIdleCheck = Date.now();

    if (r.status != 'ok') {
      this.executeConnectCallback("no_auth");
      this.disconnect();
      this.eventsService.publishError('CHATSERVICE', 'authenticate', r.status);
      return;
    }

    if (r.user_handle != r.user_handle) {
      this.executeConnectCallback("no_auth");
      this.disconnect();
      this.eventsService.publishError('CHATSERVICE', 'authenticate', "user_handles dont match");
      return;
    }

    this.executeConnectCallback("connected");
    this.eventsService.publishError('CHATSERVICE', 'authenticate', r.status);
    this.refreshonlinecontacts();
  }


  // ----------------------- public interface -----------------------


  public isConnected() {
    return (this.connection_state == 'connected');
  }

  public async sendMessage(to_user_handle: string, message: string, options: any, attachments: any) {

    if (message == "/disconnect") {
      this.disconnect();
      return;
    }

    if (!this.isConnected()) {
      let result: any = await new Promise(resolve => this.connect(resolve));
      if (result != "connected") {
        console.log("CHATSERVICE: sendmessage failed, offline ");
        return ERR_OFFLINE;
      }
    }

    let opts = {};
    if (options != null) {
      if (options["password"] != null) {
        opts["password"] = options.password;
      } else
      if (options.expires != null) {
        opts["expires"] = options.expires;
      } else
      if (options.replyto != null) {
        opts["replyto"] = options.replyto;
      }
    }

    let r: any = await new Promise(resolve => this.socket.emit(
        "sendmessage",
        to_user_handle,
        message,
        opts,
        attachments,
        data => resolve(data)
      )
    );

    this.lastIdleCheck = Date.now();

    if (r.status ==  "blocked") {
      if (r.by == "buddy") {
        return ERR_YOUAREBLOCKED
      }
      return ERR_YOUDIDBLOCK;
    }

    if (r.status == "congestion") {
      return ERR_CONGESTION;
    }

    if (r.status == "non_receiver") {
      return ERR_NONRECEIVER;
    }

    if (r.status == "repetition") {
      return ERR_REPETITION;
    }

    if (r.status == "limit_reached") {
      return ERR_LIMITREACHED;
    }

    if (r.status != 'ok') {
      console.log("CHATSERVICE: sendmessage failed ",r);
      return ERR_FAILED;
    }

    return OK;
  }

  public sendTyping(to_user_handle: string) {
    if (!this.isConnected()) return ERR_OFFLINE;

    let now = Date.now();
    if ((now-this.last_typing) < this.TYPING_THROTTLE_MILLIS) return;
    this.last_typing = now;

    this.lastIdleCheck = Date.now();
    this.socket.emit('typing', to_user_handle, () => {});
  }

  public async sendDisplayed(to_user_handle: string) {
    if (!this.isConnected()) return ERR_OFFLINE;
    this.lastIdleCheck = Date.now();
    this.socket.emit('displayed', to_user_handle, () => {});

  }

  public async deleteMessage(userHandle: string, messageID: string) {
    if (!this.isConnected()) {
      let result: any = await new Promise(resolve => this.connect(resolve));
      if (result != "connected") return ERR_OFFLINE;
    }

    let r: any = await new Promise(resolve => this.socket.emit(
        "deletemessage",
        userHandle,
        messageID,
        data => resolve(data)
      )
    );

    this.lastIdleCheck = Date.now();
    if (r.status != 'ok') {
      console.log("CHATSERVICE: deletemessage failed ",r);
      return ERR_FAILED;
    }

    return OK;
  }

  public async deleteDialog(user_handle: string) {
    if (!this.isConnected()) {
      let result: any = await new Promise(resolve => this.connect(resolve));
      if (result != "connected") return ERR_OFFLINE;
    }

    let r: any = await new Promise(resolve => this.socket.emit(
        "resetchat",
        user_handle,
        data => resolve(data)
      )
    );

    this.lastIdleCheck = Date.now();
    if ((r.status != 'ok') && (r.status != "not_found")) {
      console.log("CHATSERVICE: resetdialog failed ",r);
      return ERR_FAILED;
    }

    await this.localDBService.deleteDialog(user_handle);
    this.eventsService.publishUpdate({});

    return OK;
  }



  public setCredentials(newUserhandle,newBearertoken) {
    console.log("CHATSERVICE: setCredentials: " + newUserhandle + ":" + newBearertoken);
    if (newUserhandle == "") {
      this.online_contacts = [];
      newBearertoken = "";
    }

    let wasConnected =  (this.connection_state == 'connected');

    if ((newUserhandle != this.user_handle) || (newBearertoken != this.bearertoken)) {
      if (wasConnected) {
        this.online_contacts = [];
        this.disconnect();
      }
    }

    this.user_handle = newUserhandle;
    this.bearertoken = newBearertoken;
  }

  public clearCredentials() {
    this.setCredentials("","");
  }

  public interact() {
    if (this.isConnected()) return;
    console.log("CHATSERVICE: interact");
    this.connect(null);
  }

  public async resolveGap(gapMessage) {
    if (!this.isConnected()) {
      let result: any = await new Promise(resolve => this.connect(resolve));
      if (result != "connected") return ERR_OFFLINE;
    }

    // check if it exists in the database
    gapMessage = await this.localDBService.getMessage(gapMessage.id);
    if (gapMessage == null) ERR_NOTFOUND;

    let chat = await this.localDBService.getDialog(gapMessage.from_handle);
    if (chat == null) {
      console.log("CHATSERVICE: trying to close a gap for a non-existing user " + gapMessage.from_handle)
      await this.localDBService.removeMessage(gapMessage.id);
      return ERR_NOTFOUND;
    }

    this.lastIdleCheck = Date.now();
    let result: any = await new Promise(resolve => this.socket.emit(
        "getmessages",
        gapMessage.from_handle,
        gapMessage._upper_id,
        this.RESOLVE_BATCH,
        data => resolve(data)
      )
    );

    if (result.status != "ok") {
      console.log("CHATSERVICE: getmessages in resolveGap failed " + result.status);
      return ERR_FAILED;
    }

    let messages = result.messages;
    // no more messages, consider this gap closed...
    if (messages.length == 0) {
      console.log("CHATSERVICE: gap resolved, no more messages");
      await this.localDBService.removeMessage(gapMessage.id);
      await this.handleActionMessages(chat);
      this.eventsService.publishUpdate(chat);
      return [];
    }

    gapMessage._count -= messages.length;

    // make the lowest message id the upper.id
    for (let i = 0; i < messages.length; i++) {
      let msgid = parseInt(messages[i].id);

      if (msgid < gapMessage._upper_id) {
        gapMessage._upper_id = msgid;
      }
    }

    await this.handleMessageUpdates(chat,messages);

    // once the gap is closed, remove it
    if (gapMessage._upper_id <= gapMessage._lower_id) {
      console.log("CHATSERVICE: gap resolved, lower id reached");
      await this.localDBService.removeMessage(gapMessage.id);
      await this.handleActionMessages(chat);
      this.eventsService.publishUpdate(chat);
      return messages;
    }

    if (gapMessage._count < 0) {
      console.log("CHATSERVICE: gap resolved, count below zero");
      await this.localDBService.removeMessage(gapMessage.id);
      await this.handleActionMessages(chat);
      this.eventsService.publishUpdate(chat);
      return messages;
    }

    // if there still is a remaining gap, update the gapMessage-entry
    console.log("CHATSERVICE: gap partially resolved");
    await this.localDBService.putMessage(gapMessage);
    await this.handleActionMessages(chat);
    this.eventsService.publishUpdate(chat);



    return messages;
  }



  // accessor to online-contacts. doesnt fetch from chatserver
  public getonlinecontacts() {
    return this.online_contacts_map;
  }

  // this actually updates the online-contacts map
  // changes are fired as an event only in case the list has changed
  public async refreshonlinecontacts() {
    let result: any = await new Promise(resolve => this.socket.emit(
        "getonlinecontacts",
        data => resolve(data)
      )
    );

    console.log("CHATSERVICE: getonlinecontacts",result);

    if (result.status != "ok") return false;

    result.online_contacts.sort();

    let haschanged = false;
    if (result.online_contacts.length != this.online_contacts.length) {
      haschanged = true;
    } else {
      for (let i = 0; i < this.online_contacts.length; i++) {
        if (this.online_contacts[i] != result.online_contacts[i]) {
          haschanged = true;
          break;
        }
      }
    }

    if (haschanged) {
      this.online_contacts = result.online_contacts;
      let m = {};
      for (let i = 0; i < this.online_contacts.length; i++) {
        m[this.online_contacts[i]] = true;
      }
      this.online_contacts_map = m;

      this.eventsService.publishOnlineContacts(this.online_contacts_map);
    }

    return result.online_contacts_map;
  }


  public async revealMessage(userHandle,messageID,password) {
    if (!this.isConnected()) {
      let result: any = await new Promise(resolve => this.connect(resolve));
      if (result != "connected") return ERR_OFFLINE;
    }

    let r: any = await new Promise(resolve => this.socket.emit(
        "revealmessage",
        userHandle,
        messageID,
        password,
        data => resolve(data)
      )
    );

    if (r.status != 'ok') {
      console.log("CHATSERVICE: revealmessage failed ",r);
      return ERR_FAILED;
    }

    return OK;
  }

  public async igniteMessage(userHandle,messageID) {
    if (!this.isConnected()) {
      let result: any = await new Promise(resolve => this.connect(resolve));
      if (result != "connected") return ERR_OFFLINE;
    }

    console.log("CHATSERVICE","################# ignite",userHandle,messageID)

    let r: any = await new Promise(resolve => this.socket.emit(
        "ignitemessage",
        userHandle,
        messageID,
        data => resolve(data)
      )
    );

    if (r.status != 'ok') {
      console.log("CHATSERVICE: ignitemessage failed ",r);
      return ERR_FAILED;
    }

    return OK;
  }


  private getConfigValue(data: any,key: string, defvalue: number): number {
    if (data == null) return defvalue;
    if (data[key] == null) return defvalue
    let v = parseInt(data[key])
    if (Number.isNaN(v)) return defvalue
    return v
  }

  public async updateConfig(data) {
    // TODO: server url wechseln können
    this.RESOLVE_BATCH = this.getConfigValue(data,"resolve_batch",DEFAULT_RESOLVE_BATCH)    
    this.TYPING_THROTTLE_MILLIS = this.getConfigValue(data,"resolve_batch",DEFAULT_TYPING_THROTTLE_MILLIS)    
    this.RECONNECT_INTERVAL = this.getConfigValue(data,"resolve_batch",DEFAULT_RECONNECT_INTERVAL)    
    this.IDLE_TIMEOUT = this.getConfigValue(data,"resolve_batch",DEFAULT_IDLE_TIMEOUT)    
  }

}
