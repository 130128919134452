import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot} from '@angular/router';
import {StorageService} from "../services/storage.service";

@Injectable({
  providedIn: 'root'
})
export class WelcomeGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router,
    private storageService: StorageService
  ) {
  }

  async canLoad() {
    const phoneNumber: string = await this.storageService.getPhoneNumber();

    if (phoneNumber) {
      await this.router.navigate(['signin'], {replaceUrl: true});
      return false;
    } else {
      return true;
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const phoneNumber: string = await this.storageService.getPhoneNumber();

    if (phoneNumber) {
      await this.router.navigate(['signin'], {replaceUrl: true});
      return false;
    } else {
      return true;
    }
  }
}
