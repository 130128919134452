import { Component, OnInit } from '@angular/core';
import {StorageService} from "../../../services/storage.service";
import {Router} from "@angular/router";
import {PopoverController} from "@ionic/angular";
import {ApiService} from "../../../services/api.service";
import {ChatService} from "../../../services/chat.service";

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  public menuItems = [];

  constructor(
    private router: Router,
    private storageService: StorageService,
    private popoverController: PopoverController,
    private apiService: ApiService,
    private chatService: ChatService
  ) {
  }

  ngOnInit() {
    this.menuItems = [
    ];
  }

  async goto(url: string) {
    if (url === 'bt') {
      // TODO: wtf?!! debug code, i assume
      // await this.storageService.bearertoken___('TEST');
    } else if (url === 'logout') {
      await this.logout();
    } else {
      await this.popoverController.dismiss();
      await this.router.navigate(['/' + url]);
    }
  }

  async logout() {
    this.chatService.clearCredentials();
    await this.popoverController.dismiss();
    await this.apiService.signOff();
  }
}
