import { Injector,NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from "@ionic/storage-angular";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StorageService } from "./services/storage.service";
import { Drivers } from "@ionic/storage";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PopoverComponent } from "./pages/inside/popover/popover.component";
import { ApiService } from "./services/api.service";
import { CachedApiService } from "./services/cachedapi.service";
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';



import { NetworkService } from "./services/network.service";
import { EventsService } from "./services/events.service";
import { ChatService } from "./services/chat.service";
import { SessionService } from "./services/session.service";
import { HttpLoadingInterceptor } from "./interceptors/http-loading-interceptor";
import { AlertService } from './services/alert.service';
import { LocaldbService } from './services/localdb.service';
import { ImageService } from './services/image.service';
import { ContactsService } from './services/contacts.service';
import { TabsService } from './services/tabs.service';
import { OnboardingService} from './services/onboarding.service';
import { lastValueFrom } from "rxjs";
import { NotificationService } from './services/notification.service';
import { LoaderService } from './services/loader.service';
import { ProfileService } from './services/profile.service';
import { DeviceService } from './services/device.service';



const config: SocketIoConfig = { 
    url: 'wss://api.desade.de:3000', 
    options: { 
        autoConnect: false,
        reconnection: false 
    } 
}; 

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http,'assets/i18n/');
}

export let AppInjector: Injector;

@NgModule({
    declarations: [AppComponent, PopoverComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(
            {"mode": "md"}
        ),
        IonicStorageModule.forRoot({
            name: 'desadedb',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory, 
                deps: [HttpClient]
            },
            defaultLanguage: 'de'
        }),
        AppRoutingModule,
        HttpClientModule,
        SocketIoModule.forRoot(config)
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpLoadingInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})

export class AppModule {

    private startparams = ""
    private lastChatRecovery: number = 0
    private paused = false

    // leave the tabs service in here, even if you don't use it.
    constructor(
        private chatService: ChatService,
        private eventsService: EventsService,
        private storageService: StorageService,
        private router: Router,
        private apiService: ApiService,
        private sessionService: SessionService,
        private alertService: AlertService,
        private localDB: LocaldbService,
        private tabsService: TabsService,
        private networkService: NetworkService,
        private images: ImageService,
        private cachedApiService: CachedApiService,
        private contactsService: ContactsService,
        private injector: Injector,
        private onboardingService: OnboardingService,
        private httpClient: HttpClient,
        private notificationService: NotificationService,
        private loaderService: LoaderService,
        private profileService: ProfileService,
        private imageService: ImageService,
        private localDBService: LocaldbService,
        private deviceService: DeviceService
    ) {
        this.initApp();
    }

    private async initApp() {
        this.startparams = window.document.location.search
        console.log("APP","START",this.startparams);
        try {
            let appconfig = await this.cachedApiService.getAppConfig()
            if (appconfig["status"] != "ok") {
                this.alertService.startupFailure(appconfig["status"],"", () => { this.initApp() } );
                return;
            }
            this.cachedApiService.updateConfig(appconfig["appconfig"]["apicache"])
            this.imageService.updateConfig(appconfig["appconfig"]["imagecache"])
            this.chatService.updateConfig(appconfig["appconfig"]["cache"])
        } catch (error) {
            console.log("APP","FAILED GETTING CONFIG");
            this.alertService.startupFailure("internal",error, () => { this.initApp() } );
            return
        }


        try {
            await this.startApp()
        } catch (error) {
            console.log("APP","FAILED STARTING APP");
            this.alertService.startupFailure("internal",error, () => { this.initApp() } );
            return
        }

        console.log("APP","STARTED");
    }

    private async startApp() {
        this.exposeDebugFunctions()

        await this.initAppLevelEventHandlers()


        let fromstartparams = await this.parseStartParams()
        if (fromstartparams) {
            return            
        }

        // check if app can start/continue (because it has a valid session)
        let sessionState = await this.tryContinueSession();
        if (sessionState["status"] != "ok") {
            // no it cant. reset
            // TODO: alert about lost session
            this.eventsService.publishSessionEvent("cantcontinue")
            return;
        }

        this.eventsService.publishSessionEvent("continue")
    }

    private async startOutside() {
        this.sessionService.setInitialized()

        let phoneNumber = await this.storageService.getPhoneNumber();
        if (phoneNumber == null) phoneNumber = "";
        if (phoneNumber != "") {
            await this.router.navigate(['/signin'], { replaceUrl: true });
            return;
        }
        await this.router.navigate(['/welcome'], { replaceUrl: true });
    }


    private async trySignIn() {
        console.log("APP","sign in begin");

        let signindata = await this.sessionService.getSignInData()
        if (signindata == null) {
            console.log("APP","sign in failed, no sign in data...");
            // no we dont.
            return {
                "status": "must_be_signed_on"
            }
        }

        let signinresult = await this.apiService.signOn(
            signindata["user_handle"],
            signindata["accesstoken"]
        )

        if (signinresult["status"] != "ok") {
            // didnt work either: startpage + alert (if necessary)
            console.log("APP","sign in failed with status " + signinresult["status"]);
            return signinresult;
        }

        console.log("APP","sign in succeeded");
        await this.sessionService.setSessionData(
            signinresult["bearertoken"],
            signinresult["bearertoken_expires"]
        )

        return signinresult
    }

    private async tryContinueSession() {

        // do i have a session!?
        let sessiondata = await this.sessionService.getSessionData()
        if (sessiondata == null) {
            // no! but maybe we have signin data... try out
            console.log("APP","no session data, trying to sign in");
            return await this.trySignIn()
        }

        this.apiService.bearertoken = sessiondata["bearertoken"]
        this.apiService.bearertoken_expires = sessiondata["bearertoken_expires"]

        // yes!
        // dont validate if session is "fresh" (save one api-request)
        // TODO: this doesnt work. recovery at this point fails if (!(await this.sessionService.isSessionExpired())) {
//        if (false) {
        if (!(await this.sessionService.isSessionExpired())) {    
            console.log("APP","session is still fresh, skip further checks");
            return {
                "status": "ok"
            }
        }
        
        return await this.tryRefreshSession()
    }

    private async tryRefreshSession() {
        // make sure session is valid (be tolerant if offline (and other cases))
        let sessionresult = await this.apiService.refreshBearerToken();

        // under some conditions we will allow the app to start even if bt-refresh has failed.
        // this means that api-calls might fail later (which we want)
        let tolerantIf = ["ok","offline","service_unavailable","internal_error","service_down"];
        if (tolerantIf.indexOf(sessionresult["status"]) != -1) {
            console.log("APP","can continue session because sessionstate is " + sessionresult["status"]);

            if (sessionresult["status"] == "ok") {
                await this.sessionService.setSessionData(this.apiService.bearertoken,this.apiService.bearertoken_expires)
            }

            return {
                "status": "ok"
            }
        }

        console.log("APP","session is invalid, trying to sign in");
        return await this.trySignIn()
    }


    private async beginSession() {

        let signindata = await this.sessionService.getSignInData();
        let sessiondata = await this.sessionService.getSessionData();

        // rewire all dependent services
        await this.localDB.setUserHandle(signindata["user_handle"]);
        this.chatService.setCredentials(
            signindata["user_handle"],
            sessiondata["bearertoken"]
        )
        this.apiService.recovery_user_handle = signindata["user_handle"];
        this.apiService.recovery_accesstoken = signindata["accesstoken"];
        this.apiService.bearertoken = sessiondata["bearertoken"];
        this.apiService.bearertoken_expires = sessiondata["bearertoken_expires"];

        // this function also decides whether to init push right now or later (onboarding)
        this.startFromLastRoute();

        // clean up tasks. delayed a bit so not everything runs at once
        this.deferredTasks();

    }

    private async endSession() {

        this.notificationService.unregister()

        this.apiService.recovery_user_handle = "";
        this.apiService.recovery_accesstoken = "";
        this.apiService.bearertoken = "";
        this.apiService.bearertoken_expires = "";

        this.chatService.clearCredentials();
        await this.localDB.setUserHandle("");
        await this.onboardingService.resetOnboardingStatus()

        await this.storageService.removeVerificationToken();
        await this.storageService.removeUnlockToggle();

        await this.sessionService.removeSessionData()
        await this.sessionService.removeSignInData()

        this.startOutside();
    }

    private async updateAppServicesAfterRecovery() {
        await this.sessionService.setSessionData(
            this.apiService.bearertoken,
            this.apiService.bearertoken_expires
        )

        this.chatService.setCredentials(
            this.apiService.recovery_user_handle,
            this.apiService.bearertoken,
        )
    }


    private isHandlingSessionEvent = false

    private async handleSessionEvent(data) {

        if (this.isHandlingSessionEvent) {
            console.log("APP","ALREADY HANDLING SESSION EVENT",data)
            return
        }

        this.isHandlingSessionEvent = true
        try {
            switch (data) {
                // on app start, if session is "fresh" enough to continue
                case "continue":
                    await this.beginSession()
                    break;
                // on app start, if session is there, but not fresh enough any longer. effect similar to signoff
                case "cantcontinue":
                    // TODO: maybe different message here
                    await this.endSession()
                    break;
                // session started with register operation
                case "register":
                    await this.beginSession()
                    break;
                // session started with signon operation                    
                case "signon":
                    await this.beginSession()
                    break;
                // session ended by user-interaction
                case "signoff":
                    await this.endSession()
                    break;
                // app found out the session is no longer valid. signin did not help
                case "lost":
                    await this.endSession()
                    await this.alertService.presentAlert(
                        "PLEASE_SIGN_IN_AGAIN",
                        "SESSION_EXPIRED"
                    )
                    break;
                // app found out the session is no longer valid. refresh worked 
                // but now other services within the app have to now about new bearertoken
                case "recover":
                    await this.updateAppServicesAfterRecovery()
                    break;
                // should never happen, but might happen
                default:
                    await this.alertService.presentGenericAppError(data)
                    console.log("APP","UNKNOWN SESSION EVENT",data)
            }
        } catch (error) {
            console.log("APP","EXCEPTION DURING SESSION EVENT",data,error)
            this.alertService.presentGenericAppError(error)
        }

        this.isHandlingSessionEvent = false
    }

    private async tryToRecoverAfterChatServiceError() {
        // means the chatserver could not authenticate the user.
        // indicates there is something wrong with the useraccount and or the session

        // only try this once every n minutes
        if ((Date.now()-this.lastChatRecovery) > 120*1000) {

            let result = await this.tryRefreshSession();
            if (result["status"] == "ok") {
                this.lastChatRecovery = Date.now()
                let recovery = await this.apiService.recoverSession();
                if (recovery == "ok") {
                    this.eventsService.publishSessionEvent("recover")
                    return;
                }
            }

        }

        this.eventsService.publishSessionEvent("lost")
    }

    private async initAppLevelEventHandlers() {
        
        // app-level handling of session-events
        this.eventsService.getSessionEvent().subscribe((data) => {
            console.log("APP","SESSIONEVENT",data)
            this.handleSessionEvent(data);
        });

        // app-level handling of certain chatservice-errors
        this.eventsService.getError().subscribe(async (data) => {
            if (
                (data.source == "CHATSERVICE") && 
                (data.action == "authenticate") && 
                (data.message == "not_found")
            ) {
                this.tryToRecoverAfterChatServiceError();
            }
        })

        this.eventsService.getNotificationActionEvent().subscribe(async (data) => {
            console.log("NOTIFICATION_EVENT",data);
            if (data["navigate_to"] != null) {
                this.router.navigateByUrl(data["navigate_to"]);
            }

        })

        this.eventsService.getAppConfigEvent().subscribe(async (data) => {
            console.log("APPCONFIG_EVENT",data);
            this.cachedApiService.updateConfig(data["apicache"])
            this.imageService.updateConfig(data["imagecache"])
            this.chatService.updateConfig(data["cache"])
        })

    }


    private async startFromLastRoute() {
        this.sessionService.setInitialized()

        //-------------------------------------------------------------------------------------
        // jump to dialog on qrcode-scan            
        //-------------------------------------------------------------------------------------
        let viaqrcode = await this.storageService.get("VIAQRCODE")
        await this.storageService.remove("VIAQRCODE")
        if ((viaqrcode != null) && (viaqrcode != "")) {
            let result = await this.profileService.getProfileByHandle(viaqrcode,"CACHED")
            if (result["status"] == "ok") {
                await this.router.navigate(["/dialog/" + viaqrcode], { replaceUrl: true });
                this.notificationService.init()
                return
            }
        }
        //-------------------------------------------------------------------------------------


        if ((await this.onboardingService.getOnboardingStatus()) == "pending") {
            console.log("APP","onboarding still pending. starting with onboarding page")
            let step = await this.onboardingService.getOnboardingStep()
            await this.router.navigateByUrl(step)
            return 
        }


        let lastRoute = "/dialoglist";
        await this.router.navigate([lastRoute], { replaceUrl: true });

        //this.chatService.interact();
        this.notificationService.init()
    }



    private async deferredTasks() {
        await new Promise(resolve => setTimeout(resolve, 5000))

        console.log("APP","running deferred tasks","apicache cleanup")
        await this.cachedApiService.cleanUp()

        await new Promise(resolve => setTimeout(resolve, 5000))
        console.log("APP","running deferred tasks","images cleanup")
        await this.images.cleanUp()

    }


    private async parseStartParams() {
        if (this.startparams == "") return false;
        const params = new URLSearchParams(this.startparams);

        //-----------------------------------------------------------------------------
        // handle qr-code scans
        //-----------------------------------------------------------------------------
        const viaqrcode = params.get("viaqrcode");
        if ((viaqrcode != null) && (viaqrcode != "")) {
            await this.storageService.set("VIAQRCODE",viaqrcode)
            console.log("APP",viaqrcode)
        } else {
            await this.storageService.remove("VIAQRCODE")            
        }
        //-----------------------------------------------------------------------------


        //-----------------------------------------------------------------------------
        // handle start-token authentification
        //-----------------------------------------------------------------------------
        const starttoken = params.get("starttoken"); 
        const startcode = params.get("startcode"); 

        if ((starttoken == "") || (startcode == "") || (starttoken == null) || (startcode == null)) return false;
        console.log("APP","STARTTOKEN",starttoken,startcode);

        await this.loaderService.showLoader("Bitte warten");
        await this.endSession()
        let result = await this.apiService.confirmVerificationCode(starttoken,startcode)
        if (result["status"] != "ok") {
            await this.loaderService.hideLoader()
            await this.alertService.presentErrorFromResult(result)
            return false
        }

        await this.sessionService.setSignInData(
            result['user_handle'],
            result['accesstoken'],
            result['accesstoken_expires']
        );
    
        await this.sessionService.setSessionData(
            result['bearertoken'],
            result['bearertoken_expires']
        );

        await this.loaderService.hideLoader();
        this.eventsService.publishSessionEvent("signon")
        return true
        //-----------------------------------------------------------------------------
    }


    private async exposeDebugFunctions() {

        /*
        window["dbg_invalidate_bearertoken"] = async () => {
          var bt = "12345";
          var expires = new Date()

          await this.sessionService.setSessionData("12345", expires.toISOString())

          let signindata = await this.sessionService.getSignInData();
          let sessiondata = await this.sessionService.getSessionData();
  
          // rewire all dependent services
          await this.localDB.setUserHandle(signindata["user_handle"]);
          this.chatService.setCredentials(
              signindata["user_handle"],
              sessiondata["bearertoken"]
          )
          this.apiService.recovery_user_handle = signindata["user_handle"];
          this.apiService.recovery_accesstoken = signindata["accesstoken"];
          this.apiService.bearertoken = sessiondata["bearertoken"];
          this.apiService.bearertoken_expires = sessiondata["bearertoken_expires"];
        }
    
        window["dbg_pretend_offline"] = async (x) => {
            this.networkService.pretendOffline = x
        }

        window["dbg_pretend_servererror"] = async (x) => {
            this.apiService.pretendServerError = x
        }

        window["dbg_image"] = async () => {
            let image = await this.images.getImage("PROFILE","1002","256_256","CACHED");
            console.log(image);
        }

        window["dbg_sessionevent"] = async (x) => {
            this.eventsService.publishSessionEvent(x);
        }

        window["dbg_failure"] = async (x) => {
            this.alertService.failure(x,"blabla");
        }

        window["dbg_showprofile"] = async (x) => {
            this.router.navigateByUrl("profile/"+x)
        }

        window["dbg_contacts"] = async (x) => {
            this.contactsService.refreshLocalContacts()
        }

        window["dbg_testpush"] = async (x) => {
            this.apiService.testPushEndpoint("Title","Body","https://api.desade.de/testicon.jpg","dialog/73c881543030363031303633")
        }

        window["dbg_testlnot"] = async (x) => {
            this.notificationService.showLocalNotification("localtest","localbody","dialog/ingo");
        }

        window["dbg_resetonboarding"] = async (x) => {
            this.onboardingService.resetOnboardingStatus()
        }

        window["dbg_disconnect"] = async (x) => {
            this.chatService.disconnect()
        }

        window["dbg_identity"] = async (x) => {
            this.alertService.failure("identity","",() => {
                console.log("retry");
            })
        }

        window["dbg_clearfirstdialogdisplay"] = async (x) => {
            await this.storageService.clearFirstDialogDisplay();
        }

        window["dbg_testblob"] = async () => {

            let datauri = 'data:image/gif;base64,R0lGODdhEAAQAMwAAPj7+FmhUYjNfGuxYYDJdYTIeanOpT+DOTuANXi/bGOrWj6CONzv2sPjv2CmV1unU4zPgISg6DJnJ3ImTh8Mtbs00aNP1CZSGy0YqLEn47RgXW8amasW7XWsmmvX2iuXiwAAAAAEAAQAAAFVyAgjmRpnihqGCkpDQPbGkNUOFk6DZqgHCNGg2T4QAQBoIiRSAwBE4VA4FACKgkB5NGReASFZEmxsQ0whPDi9BiACYQAInXhwOUtgCUQoORFCGt/g4QAIQA7'

            let res = await this.httpClient.get(datauri,{
                responseType: "blob",
            })

            let blob = await lastValueFrom(res)
            console.log(blob)

        }


        */

        window["dbg_onboarding"] = async (x) => {
            this.router.navigateByUrl("onboarding")
        }


        window["dbg_dialogs"] = async () => {

            let dialogs = await this.localDBService.getDialogs();
            console.log("LOCAL DIALOGS",dialogs)

            let list = await this.apiService.getDialogsList()
            console.log("REMOTE DIALOGS",list)
        }

        window["dbg_flush"] = async (handle) => {
            await this.profileService.setFlushOnNextLoad(handle)
        }


    }

}


    
