import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {finalize, Observable} from "rxjs";
import {LoaderService} from "../services/loader.service";

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {

  constructor(
    private loaderService: LoaderService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const {url} = req;
    if (url.indexOf('/assets/i18n/') === 0) {
      return next.handle(req);
    } else {
      return next.handle(req)
    }
  }
} 
