import { Injectable } from '@angular/core';
import { LoadingController,SpinnerTypes } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader = null;

  constructor(
    public loadingController: LoadingController
  ) { }

  /**
   * spinner: bubbles | circles | crescent
   * @param message
   */
  async showLoader(message: string = undefined) {

    if (this.loader != null) return;

    try {
      let top = await this.loadingController.getTop()
      console.log("LOADER","SHOW",top);
  
      
      if ((message != "") || (message != null)) {
        message = undefined;
      }
      this.loader = await this.loadingController.create({
        showBackdrop: true,
        backdropDismiss: false,
        keyboardClose: false,
        spinner: "circular",
        message: message,
        cssClass: 'desade-ion-loader',
      })
  
      console.log("LOADER","SHOW",this.loader);
      this.loader.present();
  
    } catch(error) {
      console.log("LOADER","SHOW",error);
    }
  }

  async hideLoader() {
    try {
      console.log("LOADER","HIDE");
      await this.loadingController.dismiss();
    } catch (error) {
      console.log("LOADER","DISMISS",error);
    }
    this.loader = null;
  }


}
