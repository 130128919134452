import { Injectable } from '@angular/core';
import { DeviceService } from "./device.service";
import { FcmService } from './fcm.service';
import { WebpushService } from './webpush.service';
import { LocalNotifications, ScheduleOptions, LocalNotificationSchema, Channel } from '@capacitor/local-notifications';
import { ActionPerformed as LocalActionPerformed } from '@capacitor/local-notifications';
import { EventsService } from './events.service';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public canLocalNotifications = false;

  constructor(
    private deviceService: DeviceService,
    private fcmService: FcmService,
    private webPushService: WebpushService,
    private eventsService: EventsService
  ) {
    this.initLocalNotifications();
  }


  public async init() {
    await new Promise(resolve => setTimeout(resolve, 2000))
    let result = await this.register();
    console.log("PUSH","register returned",result)

    await new Promise(resolve => setTimeout(resolve, 2000))
    await this.initLocalNotificationsBrowser()
  }

  private async initLocalNotifications() {

    let isNative = await this.deviceService.isNativeApp()
    if (isNative) {
      await this.initLocalNotificationsNative()
      return
    }

  }

  private async initLocalNotificationsNative() {

    let chdef: Channel = {
      id: "main",
      name: "Main",
      importance: 5,
      visibility: 1
    };

    try {
      await LocalNotifications.createChannel(chdef)
      this.canLocalNotifications = true;

      LocalNotifications.addListener('localNotificationActionPerformed', (data: LocalActionPerformed) => {
        if (data.notification.extra != "") {
          this.eventsService.publishNotificationActionEvent(data.notification.extra);
        }
      });

    } catch (error) {
      console.log("LOCALNOTIFICATION", "failed to create channel", error)
    }
  }

  public async initLocalNotificationsBrowser() {
    if (window.Notification == null) return;
    let permission = Notification.permission;

    if (permission === "granted") return;
    
    await Notification.requestPermission();
  }

  public async register() {
    let isNativeApp = await this.deviceService.isNativeApp()
    if (isNativeApp) {
      return await this.fcmService.register()
    }

    return await this.webPushService.register()
  }

  public async unregister() {
    let isNativeApp = await this.deviceService.isNativeApp()
    if (isNativeApp) {
      return await this.fcmService.unregister()
    }

    return await this.webPushService.unregister()
  }

  public async showLocalNotification(title, body, navigateTo: string) {
    
    let isNative = await this.deviceService.isNativeApp()
    if (isNative) {
      return await this.showLocalNotificationNative(title,body,navigateTo)
    }
    return await this.showLocalNotificationBrowser(title,body,navigateTo)
  }

  private async showLocalNotificationNative(title,body,navigateTo: string) {
    try {
      let y: LocalNotificationSchema = {
        id: 0,
        title: title,
        body: body,
        channelId: "main",
        extra: {
          "navigate_to": navigateTo
        }
      }

      let x: ScheduleOptions = {
        notifications: [y]
      }

      LocalNotifications.schedule(x)

    } catch (error) {
      console.log("LOCALNOTIFICATION", "failed", error)
    }
  }

  private async showLocalNotificationBrowser(title,body,navigateTo: string) {
    if (window.Notification == null) return;
    let permission = Notification.permission;
    if (permission !== "granted") return;


    var notification = new Notification(title, { 
      "body": body,
      "data": navigateTo
    });
    notification.onclick = () => {
         this.eventsService.publishNotificationActionEvent({
          "navigate_to": navigateTo
         })
         notification.close();
         window.parent.focus();
    }
  }

  private _suppressLocalNotificationsForHandle: string = "";
  public suppressLocalNotificationsForHandle(user_handle) {
      this._suppressLocalNotificationsForHandle = user_handle
  }

  public suppressLocalNotification(user_handle) {
    return (this._suppressLocalNotificationsForHandle == user_handle)
  }


}
