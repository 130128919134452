import {Injectable} from '@angular/core';
import {NativeBiometric, BiometricOptions} from "capacitor-native-biometric";

@Injectable({
  providedIn: 'root'
})
export class BiometricsService {

  private lastVerification = 0

  constructor(
  ) { }

  public async canVerifyIdentity(): Promise<boolean> {
    try {
      let res = await NativeBiometric.isAvailable({
        useFallback: true
      })
      console.log("BIOMETRIC_AUTH","availability",res)
      return res.isAvailable
    } catch (error) {
      let msg = error["Message"]
      if (msg != null) msg = "undefined"
      console.log("BIOMETRIC_AUTH","isAvailable raised error: " + msg)
      return false
    }
  }

  public async verifyIdentity() {
    if ((await this.canVerifyIdentity()) == false) {
      console.log("BIOMETRIC_AUTH","aborting, feature is unavailable")
      return false;
    }

    try {
      let res = await NativeBiometric.verifyIdentity({
        useFallback: true,
        reason: "Bitte bestätige deine Identität, bevor du die App nutzen kannst", // iOS
        title: "Entsperren",
        subtitle: "",
        description: "Bitte bestätige deine Identität, bevor du die App nutzen kannst",
        negativeButtonText: "Abbrechen",
        maxAttempts: 3
      })
      console.log("BIOMETRIC_AUTH","verifyIdentity returned",res)
      this.lastVerification = Date.now()
      return true;
    } catch (error) {
      console.log("BIOMETRIC_AUTH","verifyIdentity raised error",error)
    }

    return false;      
  }

  public recentlyVerified(millis) {
    return ((Date.now() - this.lastVerification) < millis)
  }


}
