import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Platform} from "@ionic/angular";
import {filter} from "rxjs/operators";
import {StorageService} from "./storage.service";
import {SessionService} from "./session.service";

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  hidePages = [
    '/',
    'welcome',
    'signinup',
    'signup',
    'signin',
    'verification',
    'profile-edit',
    'onboarding',
    'privacy',
    'profile-information',
    'profile-picture',
    'profile-gallery',
    "dialog"
  ];

  constructor(
    private router: Router,
    private platform: Platform,
    private storageService: StorageService,
    private sessionService: SessionService
  ) {
    this.platform.ready().then(() => {
      this.navEvents();
    });
  }

  private navEvents() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {
      this.showHideTabs(e)
    });
  }

  async showHideTabs(e) {
    if (!this.sessionService.getInitialized()) {
      this.hideTabs();
      return;
    }

    if (!(await this.sessionService.hasSessionData())) {
      this.hideTabs();
      return;
    } 

    const urlArray = e.url.split('/');
    const pageUrl = urlArray[urlArray.length - 1];
    const page = pageUrl.split('?')[0];
    let shouldHide = this.hidePages.indexOf(page) > -1;

    if (!shouldHide) {
      if (urlArray.length > 2) {
        shouldHide = (urlArray[urlArray.length-2] == "dialog")
      }
    }

    if (!shouldHide) {
      shouldHide = (pageUrl.indexOf("failure?errorclass=identity&") !== -1);
    }

    if (shouldHide) {
      this.hideTabs();
      return
    }
    this.showTabs();
  }

  public hideTabs() {
    const tabBar = document.getElementById('desadeTabs');
    if (tabBar.style.display !== 'none') tabBar.style.display = 'none';
  }

  public showTabs() {
    const tabBar = document.getElementById('desadeTabs');
    if (tabBar.style.display !== 'flex') tabBar.style.display = 'flex';
  }

}
