import { Injectable } from '@angular/core';
import { Storage } from "@ionic/storage";

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(private storage: Storage) {
        this.init()
    }

    async init() {
        this.storage = await this.storage.create();
    }

    async getVerificationToken() {
        return await this.get('verificationToken');
    }

    async setVerificationToken(token: string) {
        return await this.set('verificationToken', token);

    }

    async removeVerificationToken() {
        return await this.storage.remove('verificationToken');
    }

    async getLastVisitedUserHandle() {
        return await this.get('lastVisitedUserHandle')
    }

    async setLastVisitedUserHandle(user_handle: string) {
        return await this.set('lastVisitedUserHandle',user_handle)
    }

    async getReportedProfiles() {
        return await this.get('reportedProfiles');
    }

    async setReportedProfiles(data: any = null) {
        return await this.set('reportedProfiles',data);
    }

    public async set(key: string, value: any) {
        if (value == null) {
            await this.storage.remove(key);
            return;
        }
        await this.storage.set(key,value)
    }

    public async get(key: string): Promise<any>  {
        return await this.storage.get(key);
    }

    public async remove(key: string) {
        await this.storage.remove(key);
    }

    public async has(key: string) {
        let x = await this.storage.get(key);
        if (x == null) return false;
        if (x == "") return false;
        return true;
    }


    async setPhoneNumber(propertyValue: string = null) {
        return await this.set('phoneNumber', propertyValue);
    }

    async getPhoneNumber() {
        return await this.get('phoneNumber');
    }

    async removePhoneNumber() {
        return await this.remove('phoneNumber');
    }

    async getUnlockToggle(): Promise<boolean> {
        return (await this.get('unlockToggle') == 'yes');
    }

    //-----------------------------------------------------------------------------------------------
    // lock-screen settings
    //-----------------------------------------------------------------------------------------------
    async setUnlockToggle(v: boolean) {
        if (v) {
            return await this.set('unlockToggle','yes');
        } else {
            await this.setUnlockEverytimeToggle(false);
            return await this.set('unlockToggle','no');

        }
    }

    async removeUnlockToggle() {
        await this.removeUnlockEverytimeToggle();
        return await this.remove('unlockToggle');
    }

    async getUnlockEverytimeToggle(): Promise<boolean> {
        return (await this.get('unlockEverytimeToggle') == 'yes');
    }

    async setUnlockEverytimeToggle(v: boolean) {
        if (v) {
            return await this.set('unlockEverytimeToggle','yes');
        } else {
            return await this.set('unlockEverytimeToggle','no');
        }
    }
    //-----------------------------------------------------------------------------------------------

    
    async removeUnlockEverytimeToggle() {
        return await this.remove('unlockEverytimeToggle');
    }

    //-----------------------------------------------------------------------------------------------
    // methods to support actions only showed the first time the user sees the "dialogs"-tab
    //-----------------------------------------------------------------------------------------------
    async isFirstDialogDisplay() {
        let x = await this.get('firstdialogdisplay')
        return ((x == null) || (x == ""));
    }

    async setFirstDialogDisplay() {
        return await this.set('firstdialogdisplay','yes');
    }

    async clearFirstDialogDisplay() {
        return await this.remove('firstdialogdisplay');
    }
    //-----------------------------------------------------------------------------------------------


    //-----------------------------------------------------------------------------------------------
    // methods to support actions only showed the first time the user sees the "search"-tab
    //-----------------------------------------------------------------------------------------------
    async isFirstSearchDisplay() {
        let x = await this.get('firstsearchdisplay')
        return ((x == null) || (x == ""));
    }

    async setFirstSearchDisplay() {
        return await this.set('firstsearchdisplay','yes');
    }

    async clearFirstSearchDisplay() {
        return await this.remove('firstsearchdisplay');
    }
    //-----------------------------------------------------------------------------------------------

}
