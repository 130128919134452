import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private connectionState = new Subject<any>();
  private error = new Subject<any>();
  private update = new Subject<any>();
  private typing = new Subject<any>();
  private delete_message_id = new Subject<any>();
  private online_contacts = new Subject<any>();
  private session_event = new Subject<any>();
  private notification_action = new Subject<any>();
  private appconfig = new Subject<any>();
  private unlock = new Subject<any>();

  publishConnectionState(data: any) {
    this.connectionState.next(data);
  }

  getConnectionState(): Subject<any> {
    return this.connectionState;
  }

  publishError(source: any, action: any, message: any) {
    this.error.next({source: source, action: action, message: message});
  }

  getError(): Subject<any> {
    return this.error;
  }

  publishUpdate(data: any) {
    this.update.next(data);
  }

  getUpdate(): Subject<any> {
    return this.update;
  }

  publishTyping(data: any) {
    this.typing.next(data);
  }

  getTyping(): Subject<any> {
    return this.typing;
  }

  publishDeleteMessage(data: any) {
    return this.delete_message_id.next(data);
  }

  getDeleteMessage(): Subject<any> {
    return this.delete_message_id;
  }

  publishOnlineContacts(data: any) {
    return this.online_contacts.next(data);
  }

  getOnlineContacts(): Subject<any> {
    return this.online_contacts;
  }


  publishSessionEvent(reason: any) {
    return this.session_event.next(reason);
  }

  getSessionEvent(): Subject<any> {
    return this.session_event;
  }


  publishNotificationActionEvent(data: any) {
    return this.notification_action.next(data);
  }

  getNotificationActionEvent(): Subject<any> {
    return this.notification_action;
  }

  publishAppConfigEvent(data: any) {
    return this.appconfig.next(data);
  }

  getAppConfigEvent(): Subject<any> {
    return this.appconfig;
  }

  publishUnlockEvent(data: any) {
    return;
    //return this.unlock.next(data);
  }

  getUnlockEvent(): Subject<any> {
    return this.unlock;
  }

}
