import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { StorageService } from "../services/storage.service";
import { SessionService } from "../services/session.service";
import { ApiService } from "../services/api.service";

@Injectable({
    providedIn: 'root'
})
export class InsideGuard implements CanLoad, CanActivate {

    constructor(
        private router: Router,
        private storageService: StorageService,
        private sessionService: SessionService,
        private apiService: ApiService
    ) { }

    async canLoad() {
        if (!this.sessionService.getInitialized()) return false;

        if (!(await this.sessionService.hasSessionData())) {
            return false;
        }
    }

    async canActivate() {
        if (!this.sessionService.getInitialized()) return false;
        if (!(await this.sessionService.hasSessionData())) {
            return false;
        }

    }
}
