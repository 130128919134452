import { Injectable } from '@angular/core';
import { Network } from "@capacitor/network";

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  public pretendOffline: boolean = false;

  constructor() { }

  async deviceConnected(): Promise<boolean> {
    let status = await Network.getStatus();

    if (this.pretendOffline) return false;

    return status['connected'];
  }
}
