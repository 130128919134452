import { Injectable } from '@angular/core';
import { NavigationExtras,Router } from '@angular/router';
import { AlertController } from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
    private translateService: TranslateService,
    private router: Router
  ) { }


  private autoTranslate(input: string) {
    if (input == null) return ""
    if (input == "") return "";

    try {
      let res = this.translateService.instant(input)
      if (res != input) return res

      let prefixed = "MSG." + input
      res = this.translateService.instant(prefixed)
      if (res != prefixed) return res
    } catch (error) {
    }

    console.log("ALERT","MISSING TRANSLATION",input)
    return input;
  }

  async presentAlert(message: string, header?: string, subHeader?: string, ) {
    // TODO: prevent "stacking" of multiple alerts
    message = this.autoTranslate(message);
    header = this.autoTranslate(header);
    subHeader = this.autoTranslate(subHeader);

    const alert = await this.alertController.create({
      cssClass: 'desade-alert',
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK'],
      backdropDismiss: false
    });
    await alert.present();
    return await alert.onDidDismiss();
  }



  async presentConfirm(message: string, header?: string, btnCancel?: string) {

    message = this.autoTranslate(message);
    header = this.autoTranslate(header);

    if (btnCancel == undefined) btnCancel = this.translateService.instant('BUTTONS.CANCEL');

    const alert = await this.alertController.create({
      cssClass: 'desade-alert',
      header: header,
      message: message,
      buttons: [
        {
          text: btnCancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'OK',
          role: 'ok',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
    return await alert.onDidDismiss();
  }

  public async handleSignInErrors(result) {
    if (result["status"] == "outdated_version") {
      this.failure("outdated_version",result["status_message"]);
      return;
    }

    let errormessage = this.translateStatusToErrorMessage(result,["ok","must_be_signed_on"]);
    if (errormessage === false) return;
    await this.presentAlert("", errormessage);    
  }

  public async handleVerificationErrors(result) {
    if (result["status"] == "outdated_version") {
      this.failure("outdated_version",result["status_message"]);
      return;
    }

    // some errors are not presented
    // e.g. if signin fails because there is no signin
    // here we just want to present errors that have informational value
    // like: you have been banned, server is down, etc...
    let errormessage = this.translateStatusToErrorMessage(result,["ok","must_be_signed_on"]);
    if (errormessage === false) return;
    await this.presentAlert("", errormessage);    
  }


  public async presentErrorFromResult(result) {
    if (result["status"] == "outdated_version") {
      this.failure("outdated_version",result["status_message"]);
      return;
    }

    let errormessage = this.translateStatusToErrorMessage(result,["ok"]);
    if (errormessage === false) return;
    await this.presentAlert("", errormessage);    
  }


  public async presentGenericAppError(hint) {

    if (typeof hint != "string") {

      if (hint != null) {
        if (typeof hint == "object") {
          if (hint.message != null) {
            hint = hint.message;
          } else {
            hint = JSON.stringify(hint);
            if (hint == "{}") hint = "";
          }
        }
      }
    }
    await this.presentAlert("MSG.GENERIC_APP_ERROR","",hint);
  }

  public translateStatusToErrorMessage(result,ignore = []) {
    if (ignore.indexOf(result["status"]) != -1) return false;

    // Generic error message
    let errormessage = "Während des Vorgangs ist ein Fehler aufgetreten.";

    // Translate error-class into error message
    try {
      errormessage = this.translateService.instant(("MSG." + result["status"]).toUpperCase());
    } catch (error) {

    }

    // if the server has provided a specific error-message, use it
    if (result["status_message"] != null) {
      errormessage = result["status_message"];
    }

    return errormessage;
  }


  public failure(errorclass,errormessage,retry = null) {
    this.retryCallback = retry;
    const params: NavigationExtras = {
      queryParams: {errorclass: errorclass, errormessage: errormessage, withretry: (retry != null) }
    }
    this.router.navigate(["/failure"],params);
  }

  public tryAgain() {
    if (this.retryCallback != null) {
      this.retryCallback();
    } else {
      this.router.navigate(["/dialoglist"]);
    }
  }


  public handleProfileError(result) {
    if (result["status"] == "outdated_version") {
      this.failure("outdated_version",result["status_message"]);
      return;
    }
    if (result["status"] == "offline") {
        this.failure("offline","");
        return;
    }
    if (result["status"] == "no_local") {
      this.failure("internal","(Server antwortet nicht)");
      return;
    }
    if (result["status"] == "not_found") {
        this.failure("not_found","");
        return;
    }
    this.failure("internal","profilepage / profile missing");
  }


  private retryCallback = null;

  public startupFailure(errorclass,errormessage,retry) {
    this.retryCallback = retry;
    const params: NavigationExtras = {
      queryParams: {errorclass: errorclass, errormessage: errormessage}
    }
    this.router.navigate(["/startupfailure"],params);
  }

  public startupRetry() {
    this.retryCallback();
  }


}
