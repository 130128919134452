import { Injectable } from '@angular/core';
import { LocaldbService } from "./localdb.service";
import { ApiService } from "./api.service";
import { CachedApiService } from "./cachedapi.service";

export const LOCAL = "LOCAL";                    // only pick local data
export const LOCAL_NOSTALE = "LOCAL_NOSTALE"     // only pick local data that has not expired yet
export const REMOTE = "REMOTE";                  // only pick remote data (update local cache)
export const REMOTE_WITH_FALLBACK = "REMOTE_WITH_FALLBACK";  // try to load remote-data, fallback to cached data (stale or not) if remote fails
export const CACHED = "CACHED";                  // if local data is stale, try to load remote-data. return error if that fails
export const CACHED_STRICT = " CACHED_STRICT";    // if local data is stale, try to load remote-data. return error if that fails
export const CACHED_WITH_BACKGROUND_REFRESH = "CACHED_WITH_BACKGROUND_REFRESH";      // return local data, refresh in background

export const MAXAGE = 600000;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private flush_on_next_load

  constructor(
    private localDBService: LocaldbService,
    private apiService: ApiService,
    private cachedApiService: CachedApiService
  ) { }


  public setFlushOnNextLoad(user_handle: string) {
    if (this.flush_on_next_load == null) {
      this.flush_on_next_load = {}
    }
    this.flush_on_next_load[user_handle] = true
  }

  public unsetFlushOnNextLoad(user_handle: string) {
    if (this.flush_on_next_load == null) return
    this.flush_on_next_load[user_handle] = false
  }

  public wantFlushOnNextLoad(user_handle: string) {
    if (this.flush_on_next_load == null) return false
    return this.flush_on_next_load[user_handle] === true

  }

  async getProfileByHandle(user_handle, strategy) {
    return await this.cachedApiService.getProfile(user_handle,strategy);
  }

  async getProfilesByHandle(user_handles) {
    let result = {};
    let load = [];

    // find out what items we do not need to refresh
    for (let i = 0; i < user_handles.length; i++) {
      let profile = await this.getProfileByHandle(user_handles[i], LOCAL);

      result[user_handles[i]] = profile;

      // if we dont have that profile or it is stale: load
      if ((profile["_stale"] === true) || (profile["status"] != "ok")) {
        console.log("##########MUST LOAD",profile)
        load.push(user_handles[i]);
      } 

    }

    if (load.length == 0) {
      console.log("PROFILESERVICE", "NOTHING TO LOAD, ALL LOCAL");
      return result;
    }

    // try to load what we do not have local or that needs refresh
    console.log("PROFILESERVICE", "LOADING", load);
    /*
    let loadresult = await this.apiService.getProfiles(load);
    if (loadresult["status"] == "ok") {
      let loadedprofiles = loadresult['profiles'];
      for (let i = 0; i < loadedprofiles.length; i++) {
        let profile = loadedprofiles[i];
        console.log("#########LOADED",profile)

        profile.status = "ok";
        await this.cachedApiService.store("profiles/" + profile['user_handle'],MAXAGE,profile)


        profile = await this.getProfileByHandle(profile['user_handle'], LOCAL_NOSTALE);
        // overwrite result with updates
        result[profile["user_handle"]] = profile;
      }
    }
    */

    for (let i = 0; i < load.length; i++) {
     let profile = await this.getProfileByHandle(load[i],REMOTE);
     result[load[i]] = profile;
    }
    return result;

  }


  // did I block this profile!?
  public async isProfileBlocked(handle: string, forceupdate: boolean = false) {
    let strategy = CACHED;
    if (forceupdate) strategy = REMOTE_WITH_FALLBACK;

    let result = await this.cachedApiService.blockedUsers(strategy);
    if (result["status"] != "ok") return result;

    let profiles = result["profiles"];
    if (profiles == null) return false;
    if (profiles.length == 0) return false;

    for (let i = 0; i < profiles.length; i++) {
      if (profiles[i] == handle) {
        return true;
      }
    }

    return false;
  }

  public async blockUser(handle: string) {
    let result = await this.apiService.blockUser(handle)
    if (result["status"] == "ok") {
      this.cachedApiService.flush("profiles/blocked")
    }
    return result;
  }

  public async unblockUser(handle: string) {
    let result = await this.apiService.unblockUser(handle)
    if (result["status"] == "ok") {
      this.cachedApiService.flush("profiles/blocked")
    }
    return result;
  }

  // with whom am i friends
  public async isFriend(handle: string, forceupdate: boolean = false) {
    let strategy = CACHED;
    if (forceupdate) strategy = REMOTE_WITH_FALLBACK;

    let result = await this.cachedApiService.friends(strategy);
    if (result["status"] != "ok") return result;

    let profiles = result["profiles"];
    if (profiles == null) return false;
    if (profiles.length == 0) return false;

    for (let i = 0; i < profiles.length; i++) {
      if (profiles[i] == handle) {
        return true;
      }
    }

    return false;
  }

  public async befriendUser(handle: string) {
    let result = await this.apiService.befriendUser(handle)
    if (result["status"] == "ok") {
      this.cachedApiService.flush("profiles/friends")
    }
    return result;
  }

  public async defriendUser(handle: string) {
    let result = await this.apiService.defriendUser(handle)
    if (result["status"] == "ok") {
      this.cachedApiService.flush("profiles/friends")
    }
    return result;
  }

  public async getGalleryIndex(user_handle: string, strategy: string = "CACHED") {
    return await this.cachedApiService.get("profilegallery/"+user_handle,strategy)
  }


  public async flushGalleryIndex(user_handle) {
    return await this.cachedApiService.flush("profilegallery/"+user_handle)
  }

  public async getProfileVisitors() {
    return await this.cachedApiService.get("profiles/self/visits","CACHED");
  }

  public async hasDialogs() {
    return await this.apiService.getHasDialogs()
  }

  public async getSettings() {
    return await this.cachedApiService.get("profiles/self/settings","REMOTE_WITH_FALLBACK");
  }

  public async getQRCode() {
    return await this.cachedApiService.get("qrcodeinvite","REMOTE_WITH_FALLBACK");
  }


}
