import { Injectable } from '@angular/core';
import { StorageService } from "./storage.service";
import { LocaldbService } from "./localdb.service";
import { ChatService } from "./chat.service";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private _initialized = false

  constructor(
    private storageService: StorageService,
    private localDBService: LocaldbService,
    private chatService: ChatService
  ) { }

  public setInitialized() {
    this._initialized = true
  }

  public getInitialized() {
    return this._initialized
  }

  private sanitizeString(s: string) {
    if (s == null) return "";
    return s.trim();
  }

  public async setSignInData(user_handle: string, access_token: string, access_token_expires: string) {
    
    user_handle = this.sanitizeString(user_handle);
    access_token = this.sanitizeString(access_token);
    access_token_expires = this.sanitizeString(access_token_expires);

    if ((user_handle == "") || (access_token == "") || (access_token_expires == "")) {
      throw new Error("setSignOnData: missing parameters " + user_handle + "," + access_token + "," + access_token_expires);
    }

    await this.storageService.set("user_handle",user_handle);
    await this.storageService.set("accesstoken",access_token);
    await this.storageService.set("accesstoken_expires",access_token_expires);
  }

  public async getSignInData() {
    let has = await this.hasSignInData();
    if (!has) return null;

    let result = {}
    result["user_handle"] = await this.storageService.get("user_handle");
    result["accesstoken"] = await this.storageService.get("accesstoken");
    result["accesstoken_expires"] = await this.storageService.get("accesstoken_expires");
    return result;
  }

  public async removeSignInData() {
    await this.storageService.remove("user_handle");
    await this.storageService.remove("accesstoken");
    await this.storageService.remove("accesstoken_expires");
    await this.storageService.clearFirstDialogDisplay();
    await this.storageService.clearFirstSearchDisplay();
  }

  public async hasSignInData() {
    let r = true;
    r = r && (await this.storageService.has("user_handle"));
    r = r && (await this.storageService.has("accesstoken"));
    r = r && (await this.storageService.has("accesstoken_expires"));
    return r;
  }

  public async getUserHandle() {
    let has = await this.hasSignInData();
    if (!has) return null;
    return (await this.getSignInData())["user_handle"];
  }


  public async setSessionData(bearertoken: string, expires: string) {
    
    bearertoken = this.sanitizeString(bearertoken);
    expires = this.sanitizeString(expires);

    if ((bearertoken == "") || (expires == "")) {
      throw new Error("setSessionData: missing parameters " + bearertoken + "," + expires);
    }

    await this.storageService.set("bearertoken",bearertoken);
    await this.storageService.set("bearertoken_expires",expires);
  }

  public async getSessionData() {
    // without signin-data, sessiondata is invalid
    let has = await this.hasSignInData();
    if (!has) return null;

    has = await this.hasSessionData();
    if (!has) return null;

    let result = {}
    result["bearertoken"] = await this.storageService.get("bearertoken");
    result["bearertoken_expires"] = await this.storageService.get("bearertoken_expires");
    return result;
  }

  public async removeSessionData() {
    await this.storageService.remove("bearertoken");
    await this.storageService.remove("bearertoken_expires");
  }

  public async hasSessionData() {
    // without signin-data, sessiondata is invalid
    let has = await this.hasSignInData();
    if (!has) return false;

    let r = true;
    r = r && (await this.storageService.has("bearertoken"));
    r = r && (await this.storageService.has("bearertoken_expires"));
    return r;
  }

  public async isSessionExpired() {
    // without signin-data, sessiondata is invalid
    let sess = await this.getSessionData();
    if (sess == null) return true;

    try {
      let expiry = new Date(sess["bearertoken_expires"]);
      return ((Date.now() - expiry.getTime()) >= 0);
    } catch (error) {

    }
    return true;
  }

  public async getBearerToken() {
    let has = await this.hasSessionData();
    if (!has) return null;

    let bearertoken = await this.storageService.get("bearertoken");
    return bearertoken;
  }


  public async rewireChatAndDatabaseServices() {
    if (!(await this.hasSessionData())) {
      await this.chatService.clearCredentials();
      await this.localDBService.setUserHandle("");
      return;
    }
  

    let signindata = await this.getSignInData();
    let sessiondata = await this.getSessionData();

    await this.localDBService.setUserHandle(signindata["user_handle"]);
    this.chatService.setCredentials(signindata["user_handle"],sessiondata["bearertoken"])

  }

  




}
