import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, RouterStateSnapshot} from '@angular/router';
import {SessionService} from "../services/session.service";
import {ChatService} from "../services/chat.service";

@Injectable({
  providedIn: 'root'
})
export class OutsideGuard implements CanLoad, CanActivate {

  constructor(
    private sessionService: SessionService,
    private chatService: ChatService
  ) {}

  async canLoad () {
    if (!(await this.sessionService.hasSessionData())) {
      this.chatService.clearCredentials();
      return true;
    } else {
      return false;
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!(await this.sessionService.hasSessionData())) {
      this.chatService.clearCredentials();
      return true;
    } else {
      return false;
    }
  }
}
