import { Injectable } from '@angular/core';
import { LocaldbService } from "./localdb.service";
import { ApiService } from "./api.service";
import { CachedApiService } from './cachedapi.service';
import { ProfileService } from './profile.service';

export const PROFILE = "PROFILE";
export const GALLERY = "GALLERY";
export const ATTACHMENT = "ATTACHMENT";

export const LOCAL = "LOCAL";                    // only pick local data
export const LOCAL_NOSTALE = "LOCAL_NOSTALE"     // only pick local data that has not expired yet
export const REMOTE = "REMOTE";                  // only pick remote data (update local cache)
export const REMOTE_WITH_FALLBACK = "REMOTE_WITH_FALLBACK";  // try to load remote-data, fallback to cached data (stale or not) if remote fails
export const CACHED = "CACHED";                  // if local data is stale, try to load remote-data. return error if that fails
export const CACHED_STRICT = " CACHED_STRICT";    // if local data is stale, try to load remote-data. return error if that fails
export const CACHED_WITH_BACKGROUND_REFRESH = "CACHED_WITH_BACKGROUND_REFRESH";      // return local data, refresh in background


export const PROFILE_PLACEHOLDER = 'assets/icon/user_placeholder.jpg';
export const IMAGE_PLACEHOLDER = 'assets/icon/image_placeholder.jpg';


const DEFAULT_MAX_LARGE = 200
const DEFAULT_MAX_SMALL = 1000

const DEFAULT_MAXAGE_PROFILE = 10*60*1000;
const DEFAULT_MAXAGE_GALLERY = 10*60*1000;
const DEFAULT_MAXAGE_ATTACHMENT = 10*60*1000;


@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private MAX_ITEMS_LARGE = DEFAULT_MAX_LARGE
  private MAX_ITEMS_SMALL = DEFAULT_MAX_SMALL
  private MAXAGE_PER_TYPE = {}

  public get_maxage_per_type(kind,resolution): number {
    // always hava a default value...
    var result = DEFAULT_MAXAGE_PROFILE

    // ... if possible, per kind
    switch (kind) {
    case PROFILE: 
      result = DEFAULT_MAXAGE_PROFILE
      break
    case GALLERY: 
      result = DEFAULT_MAXAGE_GALLERY
      break
    case ATTACHMENT: 
      result = DEFAULT_MAXAGE_ATTACHMENT
      break
    }

    // check against stuff like "PROFILE_64_64" or "GALLERY_256_256_TR"
    let image_definition = kind + "_" + resolution

    let max_key_length = 0
    for (var key in this.MAXAGE_PER_TYPE) {
      if (!this.MAXAGE_PER_TYPE.hasOwnProperty(key)) continue

      // take the value of the longest key that endpoint startswith
      // in order to be able to specifiy a hierarchical per-endpoint
      // max-age
      let value = this.MAXAGE_PER_TYPE[key]
      if (image_definition.startsWith(key) && (key.length >= max_key_length)) {
        result = value
        max_key_length = key.length
      }
    }
    return result
  }


  constructor(
    private localDB: LocaldbService,
    private api: ApiService,
    private cachedApi: CachedApiService,
    private profileService: ProfileService
  ) { }


  private async getPictureHash(kind,handle: string) {

    // only works for profile pictures
    if (kind != "PROFILE") return ""

    let profile = await this.profileService.getProfileByHandle(handle,"CACHED")
    if (profile["status"] != "ok") return ""
    return profile["profile"]["picture"]
  }


  private cachableStatus(status) {
    return ((status == "ok") || (status == "not_found"));
  }

  private async loadImageLocal(kind,handle,resolution) {
    let res = await this.localDB.getImage(kind,handle,resolution);
    if (res == null) {
      return {
        "status": "no_local"
      }
    }
    return res;
  }

  private async loadImageRemote(kind,handle,resolution) {
    let data;
    let maxage = this.get_maxage_per_type(kind,resolution)
    if (kind == PROFILE) {
      data = await this.api.getProfileImage(handle,resolution)
    } else
    if (kind == GALLERY) {
      data = await this.api.getGalleryImageEx(handle,resolution)
    } else
    if (kind == ATTACHMENT) {
      data = await this.api.getAttachment(handle,resolution)
    } else {
      return {
        "status": "invalid_parameters"
      }
    }

    if (this.cachableStatus(data["status"])) {
      // we only store successfully retrieved images
      // if an image was not found, that is also a successful retrieval
      await this.storeImage(kind,handle,resolution,maxage,data);
    }
    
    return data;
  }

  private async storeImage(kind,handle,resolution,expires,data) {
    if (kind == PROFILE) {
      // once we get a new profile-image, all other resolutions with a different hash
      // become stale and should be removed
      data.hash = await this.getPictureHash(kind,handle)
      const images = await this.localDB.getAllImages(kind,handle)
      for (let i = 0; i < images.length; i++) {
        if (images[i]["hash"] != data["hash"]) {
          console.log("IMAGESERVICE:"+kind+":"+handle+":"+resolution,"removing stale resolution")
          await this.localDB.removeImageById(images[i]["local_id"])
        }
      }
    } else {
      data.hash = ""
    }

    // sometimes we end up having multiple versions of the same image
    // before storing, we try to get rid of them
    let c = 10
    while (c > 0) {
      let local = await this.loadImageLocal(kind,handle,resolution);
      if (local["local_id"] == null) break
      await this.localDB.removeImageById(local["local_id"])
    }

    if (!this.cachableStatus(data["status"])) {
      return
    } 

    data.createdat = Date.now();
    data.kind = kind;
    data.handle = handle;
    data.resolution = resolution;

    data.expires = Date.now() + expires;

    return await this.localDB.putImage(data);
  }

  private async needsRefresh(data) {
    if (!this.cachableStatus(data["status"])) {
      return "no_local";
    }

    const now = Date.now()
    if (data["expires"] < now) {
      console.log("IMAGESERVICE:"+data["kind"]+":"+data["handle"]+":"+data["resolution"],"stale",now,data["expires"],data["local_id"])
      return "stale";
    }

    // if there is a picture, compare with its hash
    // dismiss if hashes differ
    // only for profile pictures
    if (data["kind"] == PROFILE) {
      const hash = await this.getPictureHash(data["kind"],data["handle"])
      if (data["hash"] != hash) {
        console.log("IMAGESERVICE:"+data["kind"]+":"+data["handle"]+":"+data["resolution"],"hashes differ",hash,data["hash"],data["local_id"])
        return "stale"
      }
    }


    return "no";
  }

  public async getImage(kind: string, handle: string, resolution: string, strategy: string) {
    const TAG = "IMAGESERVICE:"+kind+":"+handle+":"+resolution+":"+strategy;
    console.log(TAG);

    if (strategy == REMOTE) {
      return await this.loadImageRemote(kind,handle,resolution);
    }
    if (strategy == LOCAL) {
      return await this.loadImageLocal(kind,handle,resolution);
    }

    var local = null;
    local = await this.loadImageLocal(kind,handle,resolution);

    let needsRefresh = await this.needsRefresh(local);

    // return local result if not stale (except for when we prefer remote)
    if ((needsRefresh == "no") && (strategy != REMOTE_WITH_FALLBACK)) {
      console.log(TAG,"returning local result");
      return local;
    }

    // if local result is stale, do not return result
    if ((needsRefresh == "stale") && (strategy == LOCAL_NOSTALE)) {
      console.log(TAG,"cached result is stale, return stale-error");
      return {
        "status": "stale"
      }
    }

    console.log(TAG,"needs refresh: " + needsRefresh);

    // return localprofile but initiate background refresh
    if (strategy == CACHED_WITH_BACKGROUND_REFRESH) {
      console.log(TAG,"returned local image, background refresh");
      this.loadImageRemote(kind,handle,resolution);
      return local;
    }

    // load remote, return nothing (=error) if that fails
    if (strategy == CACHED_STRICT) {
      console.log(TAG,"fetching remote image, fail on errors");
      return await this.loadImageRemote(kind,handle,resolution);
    }

    // just try to refresh. if that fails, return local (=stale) profile
    let remote = await this.loadImageRemote(kind,handle,resolution);
    if (remote["status"] != "ok") {
      return local;
    }
    console.log(TAG,"returning refreshed, remote image");
    return remote;

  }

  // returns the data-url of the image (without meta-data)
  public async getImageDataURL(kind: string, handle: string, resolution: string, strategy: string) {
    let image = await this.getImage(kind,handle,resolution,strategy);
    if (image["status"] != "ok") {

      if (kind == PROFILE) {
        return PROFILE_PLACEHOLDER;
      }

      return IMAGE_PLACEHOLDER;
    }

    return image["picture"];
  }

  public async removeImageFromCache(kind: string, handle: string) {
    return await this.localDB.removeImageByHandleAndKind(kind,handle);
  }


  public async cleanUp() {
    // there is no reliable way to find out total/free diskspace
    // just remove oldest entries if db is larger than x
    this.localDB.cleanUpImages(this.MAX_ITEMS_SMALL,this.MAX_ITEMS_LARGE)
  }

  private getConfigValue(data: any,key: string, defvalue: number): number {
    if (data[key] == null) return defvalue
    let v = parseInt(data[key])
    if (Number.isNaN(v)) return defvalue
    return v
  }

  public updateConfig(data) {
    this.MAX_ITEMS_LARGE = this.getConfigValue(data,"max_items_large",DEFAULT_MAX_LARGE)    
    this.MAX_ITEMS_SMALL = this.getConfigValue(data,"max_items_small",DEFAULT_MAX_SMALL) 

    if ((data["maxage_per_type"] != null) && (data["maxage_per_type"] instanceof Object)) {
      this.MAXAGE_PER_TYPE = data["maxage_per_type"]
    }
  }

}
