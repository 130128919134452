import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';


const ONBOARDING_LONG_CHECK_INTERVAL = 7 * 24 * 60 * 60 * 1000
const ONBOARDING_SHORT_CHECK_INTERVAL = 4 * 60 * 60 * 1000

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(
    private apiService: ApiService,
    private storageService: StorageService
  ) { }


  // force three states: done, pending and unknown
  private async localOnboardingStatus() {
    let status = await this.storageService.get('onboarding_status')
    if ((status != "done") && (status != "pending")) {
      return "unknown"
    }
    return status
  }

  private async updateOnboardingStatus() {
    // even failed attempts will be remembered, so we do not check
    // too often. (means: its better to have fewer requests and accurate data)
    await this.storageService.set('onboarding_lastcheck', Date.now())

    let result = await this.apiService.getOnboarding()

    console.log("ONBOARDING",result)
    if ((result["status"] == "done") || (result["status"] == "pending")) {

      // reset onboarding step if status switched from done to pending
      let oldstatus = await this.localOnboardingStatus()
      if ((oldstatus == "done") && (result["status"] == "pending")) {
        await this.storageService.set('onboarding_step', "")
      }


      await this.storageService.set('onboarding_status', result["status"])
    }

    return await this.localOnboardingStatus()
  }

  public async getOnboardingStatus() {
    // fetch and validate from local storage
    let status = await this.localOnboardingStatus()

    // find out if we have to check the server-side state of the onboarding-status
    // we check this from time to time
    let lastcheck = parseInt(await this.storageService.get('onboarding_lastcheck'))
    if (isNaN(lastcheck)) lastcheck = 0

    let now = Date.now()
    let checkinterval = ONBOARDING_SHORT_CHECK_INTERVAL

    if (status === "done") {
      checkinterval = ONBOARDING_LONG_CHECK_INTERVAL
    }

    // so, we have to check...
    if ((now - lastcheck) > checkinterval) {
      return await this.updateOnboardingStatus()
    }

    // we dont have to check, return the (local) status
    return status
  }

  public async setOnboardingPrivacy(data: string) {
    let result = await this.apiService.setOnboardingPrivacy(data)
    return result
  }

  public async setOnboardingDone() {
    let result = await this.apiService.setOnboardingDone()
    console.log("ONBOARDING",result)

    if (result["status"] == "ok") {
      await this.storageService.set('onboarding_lastcheck', Date.now())
      await this.storageService.set('onboarding_status', "done")
      await this.storageService.set('onboarding_step', "")
    }

    return result
  }

  public async resetOnboardingStatus() {
    await this.storageService.set('onboarding_lastcheck', 0)
    await this.storageService.set('onboarding_status', "unknown")
    await this.storageService.set('onboarding_step', "")
    await this.apiService.resetOnboarding()
  }

  public async getOnboardingStep() {
    let step = await this.storageService.get('onboarding_step')

    let validsteps = [
      "onboarding",
      "onboarding/profile-information",
      "onboarding/profile-picture",
      "onboarding/profile-gallery",
      "onboarding/privacy"
    ]

    if (validsteps.indexOf(step) == -1) {
      step = validsteps[0]
    }

    return step
  }


  public async setOnboardingStep(step) {
    await this.storageService.set('onboarding_step', step)
  }



}
