import { Injectable } from '@angular/core';
import { Md5 } from "ts-md5/dist/md5";
import { DeviceService } from "./device.service";
import { StorageService } from "./storage.service";
import { ApiService } from "./api.service";
import { LocaldbService } from "./localdb.service";
import { Contacts, GetContactsOptions,PhonePayload,NamePayload } from "@capacitor-community/contacts";
import { AlertService } from './alert.service';

const HASHSECRET = 'contacthash';

const PENDING_REMOVE = "pending_remove"
const PENDING_UPSERT = "pending_upsert"
const SYNCED = "synced"

const FULL_SYNC_INTERVAL_MILLIS = 1*24*60*60*1000;
const THROTTLED_LOCAL_REFRESH_INTERVAL_MILLIS = 1*60*1000;

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  public hasRequestedPermissionsSinceAppStart = false

  constructor(
    private deviceService: DeviceService,
    private storageService: StorageService,
    private apiService: ApiService,
    private localdbService: LocaldbService,
    private alertService: AlertService
  ) { }

  // get matching contacts for the user_handle
  public async getByUserHandle(user_handle) {
    return await this.localdbService.getLocalContact(user_handle)
  }

  // get all contacts
  public async getAll() {
    return await this.localdbService.getLocalContacts()
  }

  // get all contacts with user_handles
  public async getContactsWithUserHandles() {
    let list = await this.localdbService.getLocalContacts()
    let result = []
    for (let i = 0; i < list.length; i++) {
      let contact = list[i]
      if ((contact.user_handle == null) || (contact.user_handle == "")) continue
      if (contact.sync_state == "pending_remove") continue;
      result.push(contact)
    }
    return result
  }

  // phonenumbers come in different styles. this function normalizes them
  private normalizeNumber(number) {
    if (number == null) return "";
    number = number.replace(/[^\d+]+/g, '');
    number = number.replace(/^00/, '+');
    let tmp = parseInt(number.substring(0, 1));
    if (tmp === 0) number = '+49' + number.substring(1);
    return number
  }

  // hashes the plaintext-phone-numbers
  private hashPhones(phones) {
    if (phones == null) return []

    const md5 = new Md5();
    let result = [];

    for (let i = 0; i < phones.length; i++) {
      let number = phones[i]["number"];

      number = this.normalizeNumber(number)
      if (number == "") continue

      let hashed = md5.start().appendStr(number + HASHSECRET).end()
      result.push(hashed)
    }
    return result
  }

  // get a hash over all contact-data to match with local data
  private hashContact(contact) {
    const md5 = new Md5();
    md5.start()
    md5.appendStr(contact.contact_id)
    md5.appendStr(contact.contact_name)
    for (let i = 0; i < contact.phones.length; i++) {
      md5.appendStr(JSON.stringify(contact.phones[i]))
    }
    return md5.end()
  }

  // input is how the plugin returns the contracts, output is how we store it
  private deviceContactToLocalContact(contact) {
    let result: any = {}
       
    result.contact_id = contact.contactId
    result.contact_name = contact.name.display
    if (result.contact_name == null) result.contact_name = ""
    result.phones = contact.phones

    result.hash = this.hashContact(result)
    result.hashed_phones = this.hashPhones(contact.phones)
    result.user_handle = ""
    result.sync_state = PENDING_UPSERT

    return result
  }

  // input is how the plugin returns the contracts, output is how we store it
  private deviceContactsToLocalContacts(contacts) {
    let result = []
    for (let i = 0; i < contacts.length; i++) {
      result.push(this.deviceContactToLocalContact(contacts[i]))
    }
    return result
  }

  // turns a list of contacts into a map of the form hash=>contact
  // where "hash" is the contact-hash, not the phone-hases
  private contactListToMap(list) {
    let result: any = {}
    for (let i = 0; i < list.length; i++) {
      result[list[i].hash] = list[i]
    }
    return result
  }



  private lastRefreshLocalContacts = 0;

  public async throttledRefreshLocalContacts() {
    let now = Date.now()
    if ((now-this.lastRefreshLocalContacts) > THROTTLED_LOCAL_REFRESH_INTERVAL_MILLIS) {
      await this.refreshLocalContacts()
    }
  }


  // update local copy of contacts
  public async refreshLocalContacts() {
    this.lastRefreshLocalContacts = Date.now();

    let deviceContacts = await this.deviceService.loadContacts()
    let current = this.contactListToMap( this.deviceContactsToLocalContacts(deviceContacts) )
    let stored = this.contactListToMap( await this.localdbService.getLocalContacts() )


    // whats new or updated
    for (const [key, value] of Object.entries(current)) {

      let update = false
      if (stored[key] == null) {
        update = true
      } else {
        if (stored[key]["hash"] != value["hash"]) {
          update = true
        }
        if (stored[key]["sync_state"] == "pending_remove") {
          update = true
        }
      }

      if (!update) continue

      await this.localdbService.putContact(value)
    }    

    // what has been removed
    for (const [key, value] of Object.entries(stored)) {
      if (current[key] != null) continue

      let contact = value
      contact["sync_state"] = PENDING_REMOVE
      await this.localdbService.putContact(contact)
    }    
  }



  // synchronize all contacts with the server
  public async fullSync() {
    // successful or not: remember when this function was called 
    console.log("CONTACTS: FULL SYNC")
    this.storageService.set("contacts_last_full_sync",Date.now())

    let hashes = []
    let contacts = await this.getAll()

    for (let i = 0; i < contacts.length; i++) {
      let contact = contacts[i]
      if (contact.sync_state == PENDING_REMOVE) continue

      hashes.push(...contact.hashed_phones)
    }
    hashes = [... new Set(hashes)]

    // shortcut if there are no contacts
    if (hashes.length == 0) {
      return {
        "status": "ok"
      }
    }

    let result = await this.apiService.synchronizeContacts(hashes)
    if (result["status"] != "ok") {
      return result
    }

    if (result["handles"] == null) {
      result["handles"] = [];
    }

    await this.allocateUserHandles(result["handles"],contacts)
    await this.markSynced(contacts)

    return {
      "status": "ok"
    }
  }

  // synchronize updated contacts (add,change,remove) with the server
  public async diffSync() {
    console.log("CONTACTS: DIFF SYNC")
    // successful or not: remember when this function was called 
    this.storageService.set("contacts_last_diff_sync",Date.now())

    let added = []
    let removed = []

    let contacts = await this.getAll()
    for (let i = 0; i < contacts.length; i++) {
      let contact = contacts[i]

      // synced means: no update necessary
      if (contact.sync_state == SYNCED) continue

      // upsert means: new or changed
      if (contact.sync_state == PENDING_UPSERT) {
        added.push(...contact.hashed_phones)
        continue
      }

      // only remove hashes that arent in the "added" list
      if (contact.sync_state == PENDING_REMOVE) {
        for (let j = 0; j < contact.hashed_phones.length; j++) {
          let hp = contact.hashed_phones[j]
          if (added.indexOf(hp) != -1) continue
          removed.push(hp)
        }
      }
    }
    added = [... new Set(added)]
    removed = [... new Set(removed)]

    // shortcut if nothing has changed
    if ((added.length == 0) && (removed.length == 0)) {
      return {
        "status": "ok"
      }
    }

    let result = await this.apiService.synchronizeUpdatedContacts(added,removed)
    if (result["status"] != "ok") {
      return result
    }

    await this.allocateUserHandles(result["handles"],contacts)
    await this.markSynced(contacts)
  }

  // mark all contacts in the list 
  private async markSynced(contacts) {
    for (let i = 0; i < contacts.length; i++) {
      let contact = contacts[i]

      if (contact.sync_state == PENDING_REMOVE) {
        this.localdbService.removeContact(contact)
        continue
      }

      contact.sync_state = SYNCED
      await this.localdbService.putContact(contact)
    }
  }

  // checks whether a hash is contained in this contacts list of phone-hashes
  private contactHasHashedNumber(contact,hash) {
    for (let i = 0; i < contact.hashed_phones.length; i++) {
      if (contact.hashed_phones[i] == hash) return true
    }
    return false
  }

  // api returns a map hash=>user_handle. this function sets the userhandle
  // on contacts that have the hash in their list of phonenumbers
  private async allocateUserHandles(handles,contacts) {

    for (const [hash, user_handle] of Object.entries(handles)) {

      for (let i = 0; i < contacts.length; i++) {
        let contact = contacts[i];
        if (!this.contactHasHashedNumber(contact,hash)) continue

        contact.user_handle = user_handle
        await this.localdbService.putContact(contact)
      }

    }
  }



  public async checkPermission(): Promise<any> {
    if (!(await this.deviceService.isNativeApp())) return "granted";

    try {
      this.deviceService.inNativeDialog = true
      let permissions = await Contacts.checkPermissions()
      this.deviceService.inNativeDialog = false
      return permissions.contacts + ""
    } catch (error) {
      this.deviceService.inNativeDialog = false
    }
    return "error"
  }

  public async requestPermission(): Promise<any> {
    if (!(await this.deviceService.isNativeApp())) return "granted";

    try {
      this.deviceService.inNativeDialog = true
      let permissions = await Contacts.requestPermissions();
      this.deviceService.inNativeDialog = false
      return permissions.contacts + ""
    } catch (error) {
      this.deviceService.inNativeDialog = false
    }
    return "error"
  }

  public async sync() {
    let last_full_sync = await this.storageService.get('contacts_last_full_sync');
    if ((last_full_sync == null) || (last_full_sync == "")) last_full_sync = 0;

    let last_diff_sync = await this.storageService.get('contacts_last_diff_sync');
    if ((last_diff_sync == null) || (last_diff_sync == "")) last_diff_sync = 0;

    let now = Date.now()

    if ((now-last_full_sync) > FULL_SYNC_INTERVAL_MILLIS) {
      // set last-date immediately. even if the call fails, we dont want to repeat too soon
      await this.storageService.set('contacts_full_diff_sync',now);
      await this.fullSync()
      return
    }
    await this.diffSync()
    return;

  }

  public async replaceNames(input,nameField,idField,subIndex = "") {
    let contacts = await this.getContactsWithUserHandles()
    if (contacts == null) return
    if (contacts.length == 0) return

    for (let i = 0; i < input.length; i++) {
      let item = input[i]

      if ((subIndex != "") && (subIndex != null)) {
        item = item[subIndex]
      }
      if (item[idField] == null) continue;

      let id = item[idField]

      for (let j = 0;j < contacts.length; j++) {
        let contact = contacts[j]
        if (contact["user_handle"] == id) {
          item[nameField] = contact["contact_name"]
          break
        }
      }
    }
  }

  public async replaceName(user_handle,defaultResult) {
    let contactData = await this.getByUserHandle(user_handle);
    if (contactData == null) return defaultResult
    return contactData['contact_name'];
  }


  // public async initialSync() {
  //   if (!(await this.deviceService.isNativeApp())) return;

  //   let didInitialSync = await this.storageService.has('contacts_initial_sync');
  //   console.log("CONTACTS","didInitialSync",didInitialSync)
  //   if (didInitialSync) return;

  //   // success or not: we are only doing this once
  //   await this.storageService.set('contacts_initial_sync','done');

  //   let permissionState = await this.checkPermission();
  //   console.log("CONTACTS","permissionstate",permissionState)
  //   if (permissionState == "denied") {
  //       // show alert, abort
  //       await this.alertService.presentAlert("Um schnell und einfach mit anderen in Kontakt treten zu können, gestatte der App den Zugriff auf deine Kontakte in den Systemeinstellungen.");
  //       return
  //   }


  //   if (permissionState != "granted") {
  //     // info nachricht, danach weiter
  //     await this.alertService.presentAlert("Um schnell und einfach mit anderen in Kontakt treten zu können, gestatte der App den Zugriff auf deine Kontakte im folgenden Dialogfenster.");
  //     if ((await this.requestPermission()) != "granted") {
  //       // evtl noch eine info-nachricht
  //       console.log("CONTACTS","permissionstate after request not granted")
  //       await this.alertService.presentAlert("Einverstanden! Du kannst jederzeit später den Zugriff auf deine Kontakte gestatten, wenn du möchtest.");
  //       return;
  //     }
  //   }

  //   console.log("CONTACTS","full sync")
  //   await this.fullSync()
  // }


  

}
